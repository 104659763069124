import React from "react";
import ShimmerRow from "./ShimmerRow"; // Assuming ShimmerRow is in the same directory

const ShimmerTable = ({ numberOfRows, numberOfCols }) => {
  // Create an array with the length of numberOfRows and map it to ShimmerRow components
  const rows = [...Array(numberOfRows)].map((_, index) => (
    <ShimmerRow key={index} numberOfCols={numberOfCols} />
  ));

  return <>{rows}</>;
};

export default ShimmerTable;
