import React from "react";
import PropTypes from "prop-types";
import { Typography, alpha } from "@mui/material";
import {
  faChalkboardUser,
  faHelmetSafety,
  faUserShield,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tag: {
    paddingTop: "3px",
    paddingBottom: "3px",
    borderRadius: "8px",
  },
  icon: {},
  label: {
    fontSize: "5px",
  },
  row: {
    gap: "1rem",
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
}));

const PermissionTag = ({ role }) => {
  const classes = useStyles();
  let Icon;
  let label;
  let color;

  switch (role) {
    case "admin":
      Icon = faUserShield;
      label = "Administrador";
      color = "#f44336"; // Red
      break;
    case "maintenance":
      Icon = faHelmetSafety;
      label = "Manutenção";
      color = "#4caf50"; // Green
      break;
    case "maintenance-manager":
      Icon = faChalkboardUser;
      label = "Gerente de Manutenção";
      color = "#ff9800";
      break;
    case "viewer":
      color = "#3f51b5";
      Icon = faUsersViewfinder;
      label = "Visualização";
      break;
    default:
      return null;
  }

  return (
    <div
      className={classes.tag}
      style={{
        border: "1px solid " + alpha(color, 0.5),
      }}
    >
      <div className={classes.row}>
        <FontAwesomeIcon className={classes.icon} color={color} icon={Icon} />
        <Typography variant="caption" color={color} className={classes.label}>
          {label}
        </Typography>
      </div>
    </div>
  );
};

PermissionTag.propTypes = {
  role: PropTypes.string.isRequired,
};

export default PermissionTag;
