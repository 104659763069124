import React from "react";
import { makeStyles } from "@mui/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    width: "100%",
  },
  label: {
    gap: "10px",
    height: "100%",
    width: "140px",
    minHeight: "40px",
    minWidth: "140px",
    maxWidth: "250px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "transparent",
    borderRadius: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  textField: {
    width: "100%",
    height: "100%",
    maxHeight: "40px",
    format: "DD/MM/YYYY",
    variant: "outlined",
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      "&:hover": {
        backgroundColor: "#eee", // Cor de fundo quando o mouse passa sobre o TextField
      },
      "&.Mui-focused": {
        borderColor: "transparent", // Cor da borda quando o mouse passa sobre o TextField
        backgroundColor: "#eee", // Cor de fundo quando o TextField está sendo editado
        "& fieldset": {
          borderWidth: 0, // Remove a borda
          notched: false, // Remove o entalhe
        },
      },
      "&.Mui-disabled": {
        backgroundColor: "#ddd", // Cor de fundo quando o TextField está desabilitado
        borderWidth: 0, // Remove a borda
        notched: false, // Remove o entalhe
      },
      "& fieldset": {
        borderWidth: 0, // Remove a borda
        notched: false, // Remove o entalhe
      },
    },
  },
  highlighted: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#eee",
    },
  },
}));

const DatePickerFormField = ({
  data,
  size,
  onChange,
  name = null,
  fieldId = null,
  icon = null,
  hint = null,
  showError = false,
  errorMessage = null,
  forceDisable = false,
  highlighted = true,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(dayjs(data));
  // const [cleared, setCleared] = React.useState(false);

  return (
    <div className={classes.row}>
      <div className={classes.label}>
        {icon && <FontAwesomeIcon icon={icon} color={"#888"} />}
        <label>{name}</label>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          name={name}
          size={size ?? "small"}
          className={`${classes.textField} ${
            highlighted ? classes.highlighted : ""
          }`}
          value={value ?? null}
          onChange={(newValue) => {
            setValue(newValue);
            onChange({ target: { name: fieldId, value: newValue } });
          }}
          slotProps={{
            field: { clearable: true },
            textField: { size: size ?? "small" },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DatePickerFormField;
