import React, { useState } from "react";
import { Button, Divider, TextField, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import apiClient from "../../api/apiClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import logo from "../../assets/eulera_logo_01.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  card: {
    // whiteSpace: "nowrap",
    width: "min-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
    borderRadius: 10,
    boxShadow: theme.shadows[0],
    backgroundColor: theme.palette.background.paper,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  imageSection: {
    minWidth: "300px",
  },
  formSection: {
    padding: "1rem",
    display: "flex",
    minWidth: "600px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

function LoginPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    console.log(process.env.REACT_APP_API_BASE_URL);

    await apiClient
      .post("/api/v1/auth/signin/", {
        username: username,
        password: password,
      })
      .then((response) => {
        // Store the authToken in a cookie
        Cookies.set("authToken", response.data.token);
        const expiresAt = new Date(
          new Date().getTime() + response.data.expires_in * 1000
        );
        Cookies.set("authTokenExpiresAt", expiresAt);
        Cookies.set("employeeId", response.data.employee_id);
        console.log(Cookies.get("authToken"));
        // Redirect the user to a protected route or dashboard
        // You can use React Router for routing
        toast.success("Login realizado com sucesso!");
        navigate("/dashboard");
        // Reset the form after login attempt
        setUsername("");
        setPassword("");
      })
      .catch((error) => {
        toast.error(
          "Erro ao realizar login, verifique suas credenciais e tente novamente!"
        );
      });

    // Reset the form after login attempt
    setLoading(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <div className={classes.row}>
          <img
            src={logo}
            alt="Logo"
            className={classes.imageSection}
            style={{ width: "10%", marginBottom: 16 }}
          />
          <Divider orientation="vertical" />
          <div className={classes.formSection}>
            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                placeholder="Email"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                placeholder="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="outlined"
                disabled={loading}
                startIcon={loading && <CircularProgress size={16} />}
              >
                Login
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
