import React from "react";
import "./TutorialSection.css";
import EuleroTooltip from "../../Tooltip";
import { Typography } from "@mui/material";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TutorialSection = ({
  children,
  number = 0,
  onClick = null,
  isActive = false,
  isCompleted = false,
}) => {
  return (
    <div
      className={`tutorialCard ${
        isActive ? "tutorialCardActive" : "tutorialCardInactive"
      }`}
      onClick={onClick}
    >
      <div
        className={`tutorialContent ${
          !onClick ? "tutorialContentNotClickable" : "tutorialContentClickable"
        }`}
      >
        <div className="columnExpanded">
          <div className="row">
            <div className="column">
              <div className="numberCircle">
                {isCompleted ? (
                  <EuleroTooltip title={`Etapa ${number} completa`} arrow>
                    <FontAwesomeIcon icon={faCheckCircle} color="#4caf50" />
                  </EuleroTooltip>
                ) : (
                  <Typography
                    variant="h6"
                    color={isActive ? "primary" : "textPrimary"}
                  >
                    {number}
                  </Typography>
                )}
              </div>
            </div>
            <div
              className={`children-container ${
                isActive ? "active" : "inactive"
              }`}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialSection;
