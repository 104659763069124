import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  column: {
    gap: "1rem",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rowEnd: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
  },
}));

const TutorialStepFour = ({ callback }) => {
  const classes = useStyles();

  const handleClick = () => {
    callback({ step: "finished" });
  };

  return (
    <div className={classes.column}>
      <div className={classes.column}>
        <Typography variant="h5">Concluído!</Typography>

        <Typography variant="body1">
          Seu ativo foi criado e conectado com sucesso ao Eulera.
        </Typography>

        <Typography variant="body1">
          A partir de agora, seu dispositivo está conectado e executará as
          coletas para aprender o comportamento do seu ativo. Isso normalmente
          leva de 1 a 5 dias para que o Eulera possa aprender o padrão de
          comportamento do seu ativo.
        </Typography>

        <Typography variant="body1">
          Assim que o Eulera tiver aprendido o comportamento do seu ativo, você
          poderá visualizar os resultados e configurar os alertas de acordo com
          a sua necessidade.
        </Typography>
      </div>

      <div className={classes.rowEnd} style={{ justifyContent: "end" }}>
        <Button
          color="success"
          variant="outlined"
          onClick={handleClick}
          endIcon={<FontAwesomeIcon icon={faCheckCircle} />}
        >
          Finalizar
        </Button>
      </div>
    </div>
  );
};

export default TutorialStepFour;
