import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";

import AlertsTableHead from "./components/AlertsTableHead";
import ShimmerTable from "../table/ShimmerTable";
import AlertService from "../../../api/services/alerts";
import { toast } from "react-toastify";
import AlertsTableRow from "./components/AlertsTableRow";
import AlertModal from "../alerts/AlertModal";

const useStyles = makeStyles((theme) => ({
  column: {
    gap: "1rem",
    height: "100%",
    display: "flex",
    padding: "3rem",
    flexDirection: "column",
    alignItems: "center",
  },
  row: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  dashboardCard: {
    gap: "1rem",
    width: "100%",
    display: "flex",
    padding: "0.1rem",
    minHeight: "150px",
    borderRadius: "10px",
    flexDirection: "column",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  infoCard: {
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
}));

const AlertsTableSection = () => {
  let timeoutId;
  const classes = useStyles();
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [alert, setAlert] = useState(null);
  const [isAlertViewOpen, setIsAlertViewOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setLoading(true);
      const alertService = new AlertService();
      alertService
        .getList({
          page: page,
          perPage: perPage,
        })
        .then((response) => {
          setAlerts(response.data["results"]);
          setTotalItems(response.data["count"]);
        })
        .catch((error) => {
          console.error("Error fetching alerts", error);
          toast.error("Erro na busca de alertas.");
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
  };

  useEffect(() => {
    if (alert) {
      setIsAlertViewOpen(true);
      // Set alert read_status as read
      // and update the alert in the alerts list
      let newAlerts = alerts.map((a) => {
        if (a.alert_id === alert.alert_id) {
          a.read_status = true;
        }
        return a;
      });
      setAlerts(newAlerts);
    } else {
      setIsAlertViewOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);

  useEffect(() => {
    fetchData();
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <div className={classes.dashboardCard}>
        <TableContainer>
          <Table>
            <AlertsTableHead />
            <TableBody>
              {loading && <ShimmerTable numberOfCols={5} numberOfRows={5} />}
              {/* {!loading && alerts.length === 0 && (
                <div className={classes.row}>
                  <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
                  <Typography variant="h6">
                    Nenhum alerta encontrado.
                  </Typography>
                </div>
              )} */}
              {!loading &&
                alerts.map((alert) => {
                  return (
                    <AlertsTableRow
                      key={alert.alert_id}
                      alert={alert}
                      callback={() => setAlert(alert)}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          page={page}
          count={totalItems}
          rowsPerPage={perPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[perPage]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
        />
      </div>
      <AlertModal
        alert={alert}
        isOpen={isAlertViewOpen}
        onClose={() => setAlert(null)}
      />
    </>
  );
};

export default AlertsTableSection;
