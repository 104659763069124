import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import AssetView from "../../assets/AssetView";

const useStyles = makeStyles((theme) => ({
  column: {
    gap: "1rem",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
  },
  rowEnd: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
  },
}));

const TutorialStepOne = ({ user, callback }) => {
  const classes = useStyles();
  const [asset, setAsset] = useState(null);
  const [isAssetViewOpen, setIsAssetViewOpen] = useState(false);

  const handleAssetViewClose = () => {
    setIsAssetViewOpen(false);
  };

  const handleAssetViewSubmit = (asset) => {
    setAsset(asset);
    callback?.call(asset);
    setIsAssetViewOpen(false);
  };

  const handleOnClick = () => {
    setIsAssetViewOpen(true);
  };

  return (
    <>
      <div className={classes.column}>
        <div className={classes.column}>
          <Typography variant="h5">Seja bem vindo ao Eulera!</Typography>
          <Typography variant="body1">
            Neste tutorial, você aprenderá a usar o Eulera para gerenciar suas
            manutenções preventivas.
          </Typography>

          <Typography variant="body1">
            Para você começar a usar o Eulera, o primeiro passo é criar um
            ativo. Ativos são os equipamentos que você deseja monitorar e
            gerenciar as manutenções preventivas.
          </Typography>

          <Typography variant="body1">
            Clique no botão "Criar Ativo" para criar o seu primeiro ativo.
          </Typography>
        </div>

        <div className={classes.rowEnd} style={{ justifyContent: "end" }}>
          {user?.company?.tutorial_progress === "1 step" ? (
            <Button
              color="primary"
              variant="outlined"
              onClick={handleOnClick}
              startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
            >
              Criar Ativo
            </Button>
          ) : (
            <Button
              color="success"
              variant="outlined"
              //   onClick={handleOnClick}
              startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
            >
              Ativo criado
            </Button>
          )}
        </div>
      </div>
      <AssetView
        asset={asset}
        isOpen={isAssetViewOpen}
        onClose={handleAssetViewClose}
        onSubmitCallback={handleAssetViewSubmit}
      />
    </>
  );
};

export default TutorialStepOne;
