import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./components/pages/LoginPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the styles for the notifications
import withAuth from "./components/global/auth/withAuth";
import DashboardLayout from "./components/pages/DashboardLayout/DashboardLayout";

function App() {
  const AuthenticatedDashboardPage = withAuth(DashboardLayout);

  return (
    <>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="dashboard/*" element={<AuthenticatedDashboardPage />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
