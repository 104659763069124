import apiClient from "../apiClient";

class DashboardService {
  constructor() {
    this.apiClient = apiClient;
  }

  getLatestAlerts() {
    return this.apiClient.get("api/v1/dashboard/alert/", {
      cache: {
        ttl: 1000 * 60 * 30,
      },
    });
  }
}

export default DashboardService;
