import React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import EuleroTooltip from "../../../global/Tooltip";

const useStyles = makeStyles((theme) => ({
  tagContainer: {
    color: "white",
    padding: "5px",
    borderRadius: "50%",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",
  },
}));

const RevokedTag = ({ accessMode }) => {
  const classes = useStyles();
  let Icon;
  let message;
  let backgroundColor = "#f44336";

  switch (accessMode) {
    case "revoked":
      Icon = faShieldHalved;
      message = "Acesso removido";
      backgroundColor = "#f57c00";
      break;
    case "blocked":
      Icon = faCancel;
      message = "Usuário bloqueado";
      backgroundColor = "#f44336";
      break;
    default:
      return null;
  }

  return (
    <EuleroTooltip title={message} placement="bottom">
      <div
        className={classes.tagContainer}
        style={{ backgroundColor: alpha(backgroundColor, 0.2) }}
      >
        <FontAwesomeIcon icon={Icon} size="1x" color={backgroundColor} />
      </div>
    </EuleroTooltip>
  );
};

RevokedTag.propTypes = {
  access_mode: PropTypes.string.isRequired,
};

export default RevokedTag;
