import { Drawer } from "@mui/material";

const SideViewer = ({
  children,
  isOpen,
  onClose,
  minWidth = "240px",
  maxWidth = "240px",
}) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        style: {
          minWidth: minWidth,
          maxWidth: maxWidth,
          zIndex: 9999,
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default SideViewer;
