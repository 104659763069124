import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import UserView from "../../global/users/UserView";
import { toast } from "react-toastify";
import apiClient from "../../../api/apiClient";
import Cookies from "js-cookie";
import NoUserCard from "./components/NoUserCard";
import PermissionTag from "./components/PermissionTag";
import RevokedTag from "./components/RevokedTag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faShield,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import ShimmerRow from "../../global/table/ShimmerRow";
import UserService from "../../../api/services/users";

const useStyles = makeStyles((theme) => ({
  column: {
    gap: "1rem",
    height: "100%",
    display: "flex",
    padding: "3rem",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
  },
  usersTableCard: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    borderRadius: "8px",
    padding: "20px",
    marginTop: "20px",
    boxShadow: theme.shadows[0],
    backgroundColor: theme.palette.background.paper,
  },
  headerTableCell: {
    fontWeight: "bold",
    gap: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  inCellContentRow: {
    display: "flex",
    gap: "10px",
    justifyContent: "start",
    alignItems: "center",
    alignContent: "start",
  },
  hoverRow: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f5f5f5",
    },
  },
}));

/**
 * Represents the UsersPage component.
 * This page is used for managing users.
 * Users can be retrieved from the API and displayed in a table.
 * The user can click on a user's row to view more details about the user.
 * The user can also add a new user by clicking on the "+ User" button.
 */
const UsersPage = () => {
  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [isUserViewOpen, setIsUserViewOpen] = useState(false);

  // Retrieve the users from the API
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = async () => {
    setLoading(true); // Set loading to true when request starts
    const userService = new UserService();
    const response = await userService.getMe();
    const employee = response.data;
    const company_id = employee.company.company_id;
    apiClient
      .get(`/api/v1/employee/?page=${page}&per_page=${perPage}`, {
        params: {
          page,
          per_page: perPage,
          company: company_id,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("authToken")}`,
        },
      })
      .then((response) => {
        setUsers(response.data.results);
        setTotalUsers(response.data.count);
      })
      .catch((error) => toast.error("Error fetching users"))
      .finally(() => setLoading(false)); // Set loading to false when request ends
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  const refreshUsers = () => {
    fetchData();
  };

  const handleOpenUserView = (user) => {
    setUser(user);
    setIsUserViewOpen(true);
  };

  const handleCloseUserView = () => {
    setIsUserViewOpen(false);
  };

  return (
    <>
      <div className={classes.column}>
        <Typography variant="h4">Gerenciamento de Usuários</Typography>
        <Typography variant="body1">
          Nestas página você pode visualizar e gerenciar os usuários do sistema.
        </Typography>

        {!loading && users && users.length === 1 && (
          <NoUserCard onAddUserClick={() => handleOpenUserView(null)} />
        )}

        {((users && users.length >= 2) || loading) && (
          <div className={classes.usersTableCard}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color={"#666"}
                        fontWeight={"bold"}
                      >
                        Nome
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <div className={classes.headerTableCell}>
                        <FontAwesomeIcon icon={faEnvelope} color={"#666"} />
                        <Typography
                          variant="body2"
                          color={"#666"}
                          fontWeight={"bold"}
                        >
                          Email
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.headerTableCell}>
                        <FontAwesomeIcon icon={faUser} color={"#666"} />
                        <Typography
                          variant="body2"
                          color={"#666"}
                          fontWeight={"bold"}
                        >
                          Criado por
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.headerTableCell}>
                        <FontAwesomeIcon icon={faShield} color={"#666"} />
                        <Typography
                          variant="body2"
                          color={"#666"}
                          fontWeight={"bold"}
                        >
                          Acesso
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <>
                      <ShimmerRow numberOfCols={4} />
                      <ShimmerRow numberOfCols={4} />
                      <ShimmerRow numberOfCols={4} />
                      <ShimmerRow numberOfCols={4} />
                    </>
                  )}

                  {!loading &&
                    users.map((user) => (
                      <TableRow
                        key={user.id}
                        onClick={() => handleOpenUserView(user)}
                        className={classes.hoverRow}
                      >
                        <TableCell>
                          <div className={classes.inCellContentRow}>
                            {user.user.first_name} {user.user.last_name} { }
                            {(user.access_mode === "revoked" ||
                              user.access_mode === "blocked") && (
                                <RevokedTag accessMode={user.access_mode} />
                              )}
                          </div>
                        </TableCell>
                        <TableCell>{user.user.email}</TableCell>
                        <TableCell>
                          {user.last_updated_by?.email ?? user.created_by.email}
                        </TableCell>
                        <TableCell>
                          <div style={{ display: "inline-block" }}>
                            <PermissionTag role={user.role} />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div
              className={classes.row}
              style={{
                marginTop: "20px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleOpenUserView(null)}
              >
                Criar usuário
              </Button>

              <TablePagination
                component="div"
                page={page}
                count={totalUsers}
                rowsPerPage={perPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[perPage]}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
              />
            </div>
          </div>
        )}

        <UserView
          user={
            user === null
              ? null
              : {
                first_name: user.user.first_name,
                last_name: user.user.last_name,
                username: user.user.username,
                role: user.role,
                access_mode: user.access_mode,
                phone: user.phone,
              }
          }
          isOpen={isUserViewOpen}
          onClose={handleCloseUserView}
          onSubmitCallback={refreshUsers}
        />
      </div>
    </>
  );
};

export default UsersPage;
