import { faIcons } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FormatForMetric from "./functions/NumberFormat";
import EuleroTooltip from "../Tooltip";

const useStyles = makeStyles((theme) => ({
  card: {
    gap: "1rem",
    padding: "1rem",
    display: "flex",
    borderRadius: "8px",
    flexDirection: "column",
    boxShadow: theme.shadows[0],
    backgroundColor: theme.palette.background.paper,
  },
  row: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
  },
  column: {
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
  },
  columnIcon: {
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
    whiteSpace: "nowrap",
    width: "min-content",
  },
  title: {
    whiteSpace: "nowrap",
    width: "min-content",
  },
}));

const MetricCard = ({
  title,
  value,
  width = "100%",
  icon = faIcons,
  color = "#555",
  message = null,
}) => {
  const classes = useStyles();

  const cardContent = (
    <div
      className={classes.card}
      style={{
        width: width,
        backgroundColor: alpha(color, 0.1),
        "&:hover": {
          cursor: "pointer",
          border: `1px solid ${color}`,
        },
      }}
    >
      <div className={classes.row}>
        <div className={classes.column}>
          <FontAwesomeIcon
            icon={icon}
            color={color}
            style={{ fontSize: "1.3rem" }}
          />
          <Typography className={classes.title} variant="h6" color={color}>
            {title}
          </Typography>
        </div>
        <div
          className={classes.column}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "end",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "end",
            alignContent: "end",
          }}
        >
          <Typography
            variant="h3"
            textAlign={"end"}
            style={{
              color: color,
              fontWeight: "bold",
            }}
          >
            <FormatForMetric value={value} />
          </Typography>
        </div>
      </div>
    </div>
  );

  return message ? (
    <EuleroTooltip title={message}>{cardContent}</EuleroTooltip>
  ) : (
    cardContent
  );
};

export default MetricCard;
