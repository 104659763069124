import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import SideViewer from "../SideViewer";
import {
  CircularProgress,
  Divider,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChalkboardUser,
  faHelmetSafety,
  faUserShield,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";
import apiClient from "../../../api/apiClient";
import { toast } from "react-toastify";
import InformationSection from "../InformationSection";
import Cookies from "js-cookie";
import AccessRemovedCard from "./AccessRemovedCard";
import UserService from "../../../api/services/users";

const useStyles = makeStyles((theme) => ({
  mainColumn: {
    gap: "1rem",
    height: "100%",
    display: "flex",
    padding: "3rem",
    flexDirection: "column",
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
  },
  savingSection: {
    gap: "10px",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    padding: "1rem",
  },
}));

const UserView = ({ user: initialUser, isOpen, onClose, onSubmitCallback }) => {
  const classes = useStyles();
  const [user, setUser] = useState(initialUser);
  const [isNewUser, setIsNewUser] = useState(!initialUser);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRevoking, setIsRevoking] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);

  useEffect(() => {
    setUser(initialUser);
    setIsNewUser(!initialUser);
  }, [initialUser]);

  useEffect(() => {
    if (
      user &&
      (user.access_mode === "blocked" || user.access_mode === "revoked")
    ) {
      setIsBlocking(true);
    } else {
      setIsBlocking(false);
    }
  }, [user]);

  const handleChange = (event) => {
    setIsChanged(true);
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handleRevoke = async () => {
    setIsRevoking(true);
    apiClient
      .delete(`/api/v1/auth/${user.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("authToken")}`,
        },
      })
      .then((response) => {
        toast.success("Acesso do usuário removido com sucesso!");
        onClose();
        onSubmitCallback();
        setUser(null);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          toast.error("Você não tem permissão suficientes.");
        } else {
          toast.error(
            "Erro removendo acesso do usuário, tente novamente mais tarde!"
          );
        }
      })
      .finally(() => {
        setIsRevoking(false);
      });
  };

  const handleSave = async () => {
    setLoading(true);

    if (isNewUser) {
      const userService = new UserService();
      const me = await userService.getMe();
      user.company_id = me.data.company.company_id;
      user.department = "Maintenance";

      apiClient
        .post("/api/v1/auth/signup/", user, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("authToken")}`,
          },
        })
        .then((response) => {
          toast.success(
            "Se usuário foi criado com sucesso! " +
              "Um email de confirmação foi enviado."
          );
          onClose();
          onSubmitCallback();
          setUser(null);
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            toast.error("Usuário com esse email já existe.");
          } else if (error.response && error.response.status === 401) {
            toast.error("Você não tem permissão suficientes.");
          } else {
            toast.error("Erro ao criar usuário, tente novamente mais tarde!");
          }
        })
        .finally(() => {
          setIsChanged(false);
          setLoading(false);
        });
    } else {
      apiClient
        .put(`/api/v1/employee/${user.id}`, user, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("authToken")}`,
          },
        })
        .then((response) => {
          toast.success("Usuário atualizado com sucesso!");
          onClose();
          onSubmitCallback();
          setUser(null);
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            toast.error("Usuário com esse email já existe.");
          } else if (error.response && error.response.status === 401) {
            toast.error("Você não tem permissão suficientes.");
          } else {
            toast.error(
              "Erro atualizando usuário, tente novamente mais tarde!"
            );
          }
        })
        .finally(() => {
          setIsChanged(false);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <SideViewer isOpen={isOpen} onClose={onClose} minWidth="600px">
        <div className={classes.mainColumn}>
          <div style={{ height: "2rem" }} />

          {/* User name for the view */}
          {!isNewUser ? (
            <Typography variant="h4">
              {user?.name} {user?.last_name}
            </Typography>
          ) : (
            <Typography variant="h4">Novo usuário</Typography>
          )}

          <div style={{ height: "2rem" }} />

          {/* User email for the view */}
          <TextField
            size="small"
            name="username"
            label="Email"
            variant="outlined"
            value={user && user ? user.username : ""}
            onChange={handleChange}
            disabled={isBlocking}
          />

          {/* User phone for the view */}
          <InputMask
            mask="(99) 99999-9999"
            maskChar={""}
            value={user && user ? user.phone : ""}
            onChange={handleChange}
            disabled={isBlocking}
          >
            {() => (
              <TextField
                size="small"
                name="phone"
                label="Telefone"
                variant="outlined"
                disabled={isBlocking}
              />
            )}
          </InputMask>

          {/* User name for the view */}
          <div className={classes.row}>
            <TextField
              size="small"
              name="first_name"
              label="Nome"
              variant="outlined"
              value={user && user ? user.first_name : ""}
              style={{ flex: 1 }}
              onChange={handleChange}
              disabled={isBlocking}
            />
            <TextField
              size="small"
              name="last_name"
              label="Sobrenome"
              variant="outlined"
              value={user && user ? user.last_name : ""}
              style={{ flex: 1 }}
              onChange={handleChange}
              disabled={isBlocking}
            />
          </div>

          <Divider
            style={{
              marginTop: "1rem",
            }}
          />

          {/* User role for the view */}
          <InputLabel id="role-label">Nível de acesso</InputLabel>
          <Select
            name="role"
            id="role-select"
            labelId="role-label"
            value={user && user.role ? user.role : ""}
            onChange={handleChange}
            size={"small"}
            disabled={isBlocking}
          >
            <MenuItem value={"admin"}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faUserShield} color="#777" />
              </ListItemIcon>
              Administrador
            </MenuItem>
            <MenuItem value={"maintenance-manager"}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faChalkboardUser} color="#777" />
              </ListItemIcon>
              Gerente de Manutenção
            </MenuItem>
            <MenuItem value={"maintenance"}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faHelmetSafety} color="#777" />
              </ListItemIcon>
              Manutenção
            </MenuItem>
            <MenuItem value={"viewer"}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faUsersViewfinder} color="#777" />
              </ListItemIcon>
              Somente visualização
            </MenuItem>
          </Select>

          <div style={{ height: "2rem" }} />
          {!isNewUser &&
            user &&
            (user.access_mode === "revoked" ||
              user.access_mode === "blocked") && <AccessRemovedCard />}

          <div className={classes.infosection}>
            <InformationSection
              title={"Como acessar o sistema?"}
              message={
                <>
                  <p>
                    Após a criação do usuário por aqui o usuário poderá acessar
                    a plataforma com o email informado. Um email de confirmação
                    será enviado para o email informado como notificação de
                    confirmação de que a conta foi criada com sucesso.
                  </p>

                  <h4>E a senha, não é necessário?</h4>

                  <p>
                    No primeiro acesso do usuário ele deve inserir a senha que
                    deseja utilizar, e essa senha será cadastrada e utilizada
                    para acessos futuros.
                  </p>
                </>
              }
            />
          </div>
        </div>
        <div className={classes.savingSection}>
          <Divider />

          <div
            className={classes.row}
            style={{
              gap: "30px",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button variant="text" color="primary" onClick={onClose}>
              Cancelar
            </Button>

            {!isNewUser &&
              user &&
              user.access_mode !== "revoked" &&
              user.access_mode !== "blocked" && (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleRevoke}
                  disabled={!isNewUser || loading || isRevoking}
                  startIcon={isRevoking && <CircularProgress size={16} />}
                >
                  Remover acesso
                </Button>
              )}

            <Button
              variant="outlined"
              color="primary"
              onClick={handleSave}
              disabled={
                (!isNewUser && !isChanged) ||
                !isNewUser ||
                loading ||
                isRevoking
              }
              startIcon={loading && <CircularProgress size={16} />}
            >
              {!isNewUser ? "Salvar" : "Criar usuário"}
            </Button>
          </div>
        </div>
      </SideViewer>
    </div>
  );
};

export default UserView;
