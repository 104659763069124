import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import UserService from "../../../api/services/users";
import TutorialSection from "./components/TutorialSection";
import { Divider, Grow } from "@mui/material";
import TutorialStepOne from "./components/TutorialStepOne";
import TutorialStepTwo from "./components/TutorialStepTwo";
import AssetService from "../../../api/services/assets";
import TutorialStepThree from "./components/TutorialStepThree";
import TutorialStepFour from "./components/TutorialStepFour";
import DeviceService from "../../../api/services/devices";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingTop: "3rem",
    paddingLeft: "3rem",
    paddingRight: "3rem",
  },
  tutorialCard: {
    gap: "0rem",
    width: "100%",
    height: "300px",
    display: "flex",
    marginBottom: "0",
    borderRadius: "10px",
    flexDirection: "row",
    paddingRight: "1rem",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
}));

const tutorialStepMapping = {
  "1 step": 1,
  "2 step": 2,
  "3 step": 3,
  "4 step": 4,
  finished: 5,
};

const TutorialDashboard = () => {
  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [asset, setAsset] = useState(null);
  const [device, setDevice] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [showingStep, setShowingStep] = useState(1);

  const fetchUser = ({ refresh = false } = {}) => {
    const userService = new UserService();
    userService
      .getMe({ refresh })
      .then((response) => {
        setUser(response.data);
        if (response.data?.company?.tutorial_progress) {
          const step =
            tutorialStepMapping[response.data.company.tutorial_progress];
          setCurrentStep(step);
          setShowingStep(step);
          if (step === 5) {
            setIsActive(false);
          } else {
            setIsActive(true);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching user", error);
      });
  };

  // General callback to fetch user data after any change in the tutorial
  // this callback is used in all steps
  const generalCallback = () => {
    fetchUser({ refresh: true });
  };

  // Step 1 callback to set the asset and fetch user data
  // this callback is used in the first step
  const stepOneCallback = (asset) => {
    if (asset) setAsset(asset);
    generalCallback();
  };

  // Step 4 callback to update the tutorial progress
  // this callback is used in the last step
  const tutorialSetStateCallback = ({ step = "4 step" } = {}) => {
    const userService = new UserService();
    userService.updateTutorialStep({ step }).then((response) => {
      generalCallback();
    });
  };

  // Step 1 - Fetch user
  // this step is executed when the component is mounted
  useEffect(() => fetchUser(), []);

  // Step 2 - Fetch asset if user is set and asset is not set
  // this step is only executed if the user is set and the asset is not set
  useEffect(() => {
    if (!asset && user) {
      if (currentStep >= 2) {
        let assetService = new AssetService();
        assetService
          .getList({ page: 1, perPage: 1 })
          .then((response) => {
            if (response.data.results.length > 0) {
              setAsset(response.data.results[0]);
            }
          })
          .catch((error) => {
            console.error("Error fetching assets", error);
          });
      }
    }
  }, [currentStep, asset, user]);

  // Step 3 - Fetch device if asset is set and device is not set
  // this step is only executed if the asset is set and the device is not set
  useEffect(() => {
    if (!device && asset) {
      if (currentStep >= 3) {
        if (!device) {
          let deviceService = new DeviceService();
          deviceService
            .getList({ page: 1, perPage: 1, assetId: asset.asset_id })
            .then((response) => {
              if (response.data.results.length > 0) {
                setDevice(response.data.results[0]);
              }
            });
        }
      }
    }
  }, [currentStep, asset, device]);

  const handleSectionClick = (section) => {
    setShowingStep(section);
  };

  if (!user) return <></>;

  return (
    <Grow in={isActive} {...(isActive ? { timeout: 1000 } : {})}>
      <div
        className={classes.cardContainer}
        style={{ display: isActive ? "block" : "none" }}
      >
        <div className={classes.tutorialCard}>
          <TutorialSection
            number={1}
            isActive={showingStep === 1}
            isCompleted={currentStep > 1}
            onClick={() => handleSectionClick(1)}
          >
            <TutorialStepOne user={user} callback={stepOneCallback} />
          </TutorialSection>
          <Divider orientation="vertical" flexItem />
          <TutorialSection
            number={2}
            isActive={showingStep === 2}
            isCompleted={currentStep > 2}
            onClick={currentStep <= 1 ? null : () => handleSectionClick(2)}
          >
            <TutorialStepTwo
              user={user}
              asset={asset}
              callback={generalCallback}
            />
          </TutorialSection>
          <Divider orientation="vertical" flexItem />
          <TutorialSection
            number={3}
            isActive={showingStep === 3}
            isCompleted={currentStep > 3}
            onClick={currentStep <= 2 ? null : () => handleSectionClick(3)}
          >
            <TutorialStepThree
              device={device}
              callback={tutorialSetStateCallback}
            />
          </TutorialSection>
          <Divider orientation="vertical" flexItem />
          <TutorialSection
            number={4}
            isActive={showingStep === 4}
            isCompleted={currentStep > 4}
            onClick={currentStep <= 3 ? null : () => handleSectionClick(4)}
          >
            <TutorialStepFour callback={tutorialSetStateCallback} />
          </TutorialSection>
        </div>
      </div>
    </Grow>
  );
};

export default TutorialDashboard;
