import React from "react";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  noAssetVibrationPatternCard: {
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    padding: "20px",
    gap: "1rem",
  },
  iconContainer: {
    padding: "10px",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginRight: "10px",
    borderRadius: "20%",
    backgroundColor: "#f5f5f5",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
  },
}));

const GenericInformationSection = ({ icon = faInfoCircle, title, message }) => {
  const classes = useStyles();
  return (
    <div className={classes.noAssetVibrationPatternCard}>
      <div className={classes.row}>
        <div className={classes.iconContainer}>
          <FontAwesomeIcon icon={icon} size="m" color="#616161" />
        </div>
        <div>
          <Typography variant="h6">{title}</Typography>
        </div>
      </div>
      <div className={classes.column}>
        <Typography variant="body2" align="center" fontStyle={"italic"}>
          {message}
        </Typography>
      </div>
    </div>
  );
};

export default GenericInformationSection;
