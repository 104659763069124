import { NumericFormat } from "react-number-format";

export default function FormatForMetric({ value }) {
  /// <summary>
  /// This component is a wrapper for the NumberFormat library
  /// It prevents the number to have too many decimal places
  /// and prevents number that are too big to not be displayed correctly
  /// If it gets above a thousand it shows the number with a K,
  /// if it gets above a million it shows the number with a M
  /// <summary>
  /// <param name="value">Number to format</param>
  /// <returns>Formatted number</returns>

  return (
    <NumericFormat
      value={value}
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={2}
      suffix={value >= 1000000 ? "M" : value >= 1000 ? "K" : ""}
    />
  );
}
