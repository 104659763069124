import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

function withAuth(WrappedComponent) {
  return (props) => {
    const authToken = Cookies.get("authToken");
    const expirationTime = Cookies.get("authTokenExpiresAt");

    const isExpired = expirationTime && new Date(expirationTime) < new Date();

    if (!authToken || isExpired) {
      return <Navigate to="/login" replace />;
    }

    return <WrappedComponent {...props} />;
  };
}

export default withAuth;
