import React from "react";
import EuleroTooltip from "../../Tooltip";
import { ServiceOrderPriorityColor } from "../../../../theme/StatusColor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronDown,
  faCircleChevronUp,
  faCircleDot,
} from "@fortawesome/free-solid-svg-icons";

const PriorityIcon = {
  low: faCircleChevronDown,
  medium: faCircleDot,
  high: faCircleChevronUp,
};

const PriorityName = {
  low: "Baixa",
  medium: "Média",
  high: "Alta",
};

const ServiceOrderPriorityTag = ({ serviceOrder }) => {
  const defaultPriority = "low";

  return (
    <EuleroTooltip
      title={PriorityName[serviceOrder?.priority ?? defaultPriority]}
    >
      <FontAwesomeIcon
        icon={PriorityIcon[serviceOrder?.priority ?? defaultPriority]}
        color={
          ServiceOrderPriorityColor[serviceOrder?.priority ?? defaultPriority]
        }
      />
    </EuleroTooltip>
  );
};

export default ServiceOrderPriorityTag;
