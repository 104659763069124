import React, { useState } from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import AlertService from "../../../api/services/alerts";
import EuleroTooltip from "../Tooltip";

const IgnoreAlertButton = ({ alert: initialAlert, size = "small" }) => {
  const [alert, setAlert] = useState(initialAlert);
  const [ignoreInProgress, setIgnoreInProgress] = useState(false);

  const ignoreAlert = async (alert) => {
    let alertService = new AlertService();
    try {
      const response = await alertService.ignore(alert.id);
      let newAlert = response.data;
      setAlert(newAlert);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao ignorar alerta");
    }
  };

  const isDisabled = ignoreInProgress || alert?.criteria === "not-a-problem";

  const buttonContent = (
    <Button
      size={size}
      color="primary"
      variant="text"
      onClick={(event) => {
        setIgnoreInProgress(true);
        event.stopPropagation();
        ignoreAlert(alert)
          .then(() => {})
          .catch((error) => {
            console.error(error);
            toast.error("Erro ao ignorar alerta");
          })
          .finally(() => {
            setIgnoreInProgress(false);
          });
      }}
      disabled={isDisabled}
      startIcon={
        ignoreInProgress ? (
          <CircularProgress size={12} />
        ) : (
          <FontAwesomeIcon icon={faEyeSlash} style={{ fontSize: "12px" }} />
        )
      }
    >
      {"Ignorar"}
    </Button>
  );

  return isDisabled ? (
    buttonContent
  ) : (
    <EuleroTooltip title="Ignorar alerta">{buttonContent}</EuleroTooltip>
  );
};

export default IgnoreAlertButton;
