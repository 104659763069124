import Cookies from "js-cookie";
import apiClient from "../apiClient";
import UserService from "./users";

class DeviceService {
  constructor() {
    this.apiClient = apiClient;
    this.authToken = Cookies.get("authToken");
  }

  getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.authToken}`,
    };
  }

  getList({ page = 1, perPage = 10, assetId = null, filter = null } = {}) {
    let params = { page, per_page: perPage };
    if (assetId) params.asset = assetId;

    return this.apiClient.get("api/v1/device/", { params, cache: false });
  }

  async getAvailableDevices({ refresh = true } = {}) {
    const userService = new UserService();
    const response = await userService.getMe();
    const employee = response.data;
    const company_id = employee.company.company_id;
    return this.apiClient.get("api/v1/device/", {
      params: { asset__is_null: true, company: company_id },
      cache: refresh ? false : { ttl: 60 * 60 * 1000 },
      headers: this.getHeaders(),
    });
  }

  attachToAsset(deviceId, assetId) {
    return this.apiClient.patch(`api/v1/device/${deviceId}/`, {
      asset: assetId,
    });
  }

  detachFromAsset(deviceId) {
    return this.apiClient.patch(`api/v1/device/${deviceId}/`, {
      asset: null,
    });
  }

  get(id) {
    return this.apiClient.get(`api/v1/device/${id}/`, { cache: false });
  }

  getEmpty() {
    return {
      nid: "",
      status: "",
      company: [],
      assets: [],
      alerts: [],
      data_collection: [],
      latest_sample: [],
    };
  }

  validateDevice(device) {
    return null;
  }

  create(device) {
    // return this.apiClient.post("api/v1/devices/", device, {
    //   headers: this.getHeaders(),
    // });
  }

  update(id, device) {
    // return this.apiClient.put(`api/v1/devices/${id}`, device, {
    //   headers: this.getHeaders(),
    // });
  }

  delete(id) {
    // return this.apiClient.delete(`api/v1/devices/${id}`, {
    //   headers: this.getHeaders(),
    // });
  }
}

export default DeviceService;
