import Cookies from "js-cookie";
import apiClient from "../apiClient";

class UserService {
  constructor() {
    this.apiClient = apiClient;
  }

  updateTutorialStep({ step = "4 step" } = {}) {
    if (!["1 step", "2 step", "3 step", "4 step", "finished"].includes(step)) {
      throw new Error("Invalid tutorial step");
    }
    // Get current user object to get the company data
    return this.getMe().then((response) => {
      const company = response.data.company;
      return this.apiClient.patch(`api/v1/company/${company.company_id}/`, {
        tutorial_progress: step,
      });
    });
  }

  getMe({ refresh = false } = {}) {
    const employeeId = Cookies.get("employeeId");
    return this.apiClient.get("api/v1/employee/" + employeeId, {
      cache: refresh
        ? false
        : {
            ttl: 60 * 60 * 1000,
          },
    });
  }

  getList({
    page = 1,
    perPage = 10,
    sortField = "id",
    sortOrder = "asc",
    filters = null,
  } = {}) {
    return this.apiClient.get("api/v1/users/", {
      params: {
        page,
        per_page: perPage,
        sort_field: sortField,
        sort_order: sortOrder,
        filters: JSON.stringify(filters),
      },
      cache: false,
    });
  }
}

export default UserService;
