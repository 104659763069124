import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import AssetService from "../../../api/services/assets";
import { toast } from "react-toastify";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import NoAssetCard from "./components/NoAssetAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faClock,
  faHospitalSymbol,
  faMarker,
} from "@fortawesome/free-solid-svg-icons";
import ShimmerRow from "../../global/table/ShimmerRow";
import AssetStateTag from "../../global/assets/components/AssetStateTag";
import AssetHealthTag from "../../global/assets/components/AssetHealthTag";
import AssetView from "../../global/assets/AssetView";

const useStyles = makeStyles((theme) => ({
  column: {
    gap: "1rem",
    height: "100%",
    display: "flex",
    padding: "3rem",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
  },
  assetTableCard: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    borderRadius: "8px",
    padding: "20px",
    marginTop: "20px",
    boxShadow: theme.shadows[0],
    backgroundColor: theme.palette.background.paper,
  },
  headerTableCell: {
    fontWeight: "bold",
    gap: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  inCellContentRow: {
    display: "flex",
    gap: "10px",
    justifyContent: "start",
    alignItems: "center",
    alignContent: "start",
  },
  hoverRow: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f5f5f5",
    },
  },
}));

const AssetsPage = () => {
  let timeoutId;
  const classes = useStyles();
  const [asset, setAsset] = useState(null);
  const [isAssetViewOpen, setIsAssetViewOpen] = useState(false);

  // Retrieve the users from the API
  const [page, setPage] = useState(1);
  const [assets, setAssets] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    if (newPage === page) {
      return;
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setLoading(true);
      const assetService = new AssetService();
      assetService
        .getList({
          page: page,
          perPage: perPage,
        })
        .then((response) => {
          setAssets(response.data["results"]);
          setTotalItems(response.data["count"]);
        })
        .catch((error) => {
          console.error("Error fetching assets", error);
          toast.error("Erro na busca de ativos.");
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
  };

  useEffect(() => {
    fetchData();
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (asset) {
      setIsAssetViewOpen(true);
    }
  }, [asset]);

  const handleCloseAssetView = () => {
    setIsAssetViewOpen(false);
    setAsset(null);
  };

  return (
    <>
      <div className={classes.column}>
        <Typography variant="h4">Gerenciamento de Ativos</Typography>
        <Typography variant="body1">
          Nestas página você pode visualizar e gerenciar os seus ativos e
          dispositivos conectados aos ativos.
        </Typography>

        {!loading && assets && assets.length === 0 && (
          <NoAssetCard onAddUserClick={() => { }} />
        )}

        {((assets && assets.length >= 1) || loading) && (
          <div className={classes.assetTableCard}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color={"#666"}
                        fontWeight={"bold"}
                      >
                        Ativo
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <div className={classes.headerTableCell}>
                        <FontAwesomeIcon icon={faMarker} color={"#666"} />
                        <Typography
                          variant="body2"
                          color={"#666"}
                          fontWeight={"bold"}
                        >
                          Marca/Modelo
                        </Typography>
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className={classes.headerTableCell}>
                        <FontAwesomeIcon icon={faCalendar} color={"#666"} />
                        <Typography
                          variant="body2"
                          color={"#666"}
                          fontWeight={"bold"}
                        >
                          Fabricação
                        </Typography>
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className={classes.headerTableCell}>
                        <FontAwesomeIcon icon={faClock} color={"#666"} />
                        <Typography
                          variant="body2"
                          color={"#666"}
                          fontWeight={"bold"}
                        >
                          Estado
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.headerTableCell}>
                        <FontAwesomeIcon
                          icon={faHospitalSymbol}
                          color={"#666"}
                        />
                        <Typography
                          variant="body2"
                          color={"#666"}
                          fontWeight={"bold"}
                        >
                          Saúde
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <>
                      <ShimmerRow numberOfCols={5} />
                      <ShimmerRow numberOfCols={5} />
                      <ShimmerRow numberOfCols={5} />
                      <ShimmerRow numberOfCols={5} />
                      <ShimmerRow numberOfCols={5} />
                      <ShimmerRow numberOfCols={5} />
                      <ShimmerRow numberOfCols={5} />
                    </>
                  )}

                  {!loading &&
                    assets.map((asset) => (
                      <TableRow
                        key={asset?.id}
                        onClick={() => {
                          setAsset(asset);
                        }}
                        className={classes.hoverRow}
                      >
                        <TableCell>
                          <div>
                            <Typography variant="body2">
                              {asset?.name}
                            </Typography>
                            <Typography variant="caption">
                              {asset?.location}
                            </Typography>
                          </div>
                        </TableCell>

                        <TableCell>
                          <div>
                            <Typography variant="body2">
                              {asset?.model?.name ?? "XX"}
                            </Typography>
                            <Typography variant="caption">
                              {asset?.brand?.name ?? "XX"}
                            </Typography>
                          </div>
                        </TableCell>

                        <TableCell>{asset.year}</TableCell>
                        <TableCell>
                          <AssetStateTag asset={asset} />
                        </TableCell>
                        <TableCell>
                          <AssetHealthTag asset={asset} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div
              className={classes.row}
              style={{
                marginTop: "20px",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={() => {
                  setAsset(null);
                  setIsAssetViewOpen(true);
                }}
              >
                + Adicionar ativo
              </Button>

              <TablePagination
                component="div"
                page={page}
                count={totalItems}
                rowsPerPage={perPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[perPage]}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
              />
            </div>
          </div>
        )}

        <AssetView
          asset={asset}
          isOpen={isAssetViewOpen}
          onClose={handleCloseAssetView}
          onSubmitCallback={fetchData}
        />
      </div>
    </>
  );
};

export default AssetsPage;
