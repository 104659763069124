import { faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import React from "react";

const AccessRemovedCard = () => {
  return (
    <div
      style={{
        padding: "10px",
        borderRadius: "8px",
        border: "1px solid #999",
        gap: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "row",
      }}
    >
      <FontAwesomeIcon icon={faUserShield} size="2x" color="#999" />
      <Typography variant="caption" color={"#999"}>
        Acesso removido ou usuário bloqueado. Contate o time de suporte para
        prover acesso novamente ou desbloquear o usuário.
      </Typography>
    </div>
  );
};

export default AccessRemovedCard;
