import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Typography, alpha } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  "information-section": {
    border: "1px solid " + alpha(theme.palette.primary.main, 0.8),
    borderRadius: "8px",
    padding: "20px",
    marginTop: "20px",
    marginBottom: "10px",
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem",
  },
  icon: {
    color: alpha(theme.palette.primary.main, 0.99),
  },
  message: {
    color: "#444",
  },
}));

const InformationSection = ({ title, message }) => {
  const classes = useStyles();
  return (
    <div className={classes["information-section"]}>
      <div className={classes.row}>
        <FontAwesomeIcon
          className={classes.icon}
          icon={faCircleInfo}
          size="2x"
        />
        <Typography variant="h5" className={classes.icon}>
          {title}
        </Typography>
      </div>

      <div style={{ marginTop: "30px" }} />

      <Typography className={classes.message}>{message}</Typography>
    </div>
  );
};

InformationSection.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default InformationSection;
