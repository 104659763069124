import React from "react";
import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EuleroTooltip from "../../Tooltip";
import { ServiceOrderStatusColor } from "../../../../theme/StatusColor";

const useStyles = makeStyles((theme) => ({
  tag: {
    gap: "5px",
    color: "white",
    display: "flex",
    maxWidth: "120px",
    fontWeight: "bold",
    fontSize: "0.75rem",
    padding: "3px 10px",
    borderRadius: "5px",
    alignItems: "center",
    flexDirection: "row",
    textTransform: "capitalize",
  },
  smallCircle: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
  },
}));

const StatusName = {
  todo: "Aberto",
  "in-progress": "Em progresso",
  done: "Fechado",
};

const ServiceOrderStatusTag = ({ serviceOrder }) => {
  const classes = useStyles();

  return (
    <EuleroTooltip title={StatusName[serviceOrder?.status ?? "todo"]}>
      <div
        className={classes.tag}
        style={{
          color: ServiceOrderStatusColor[serviceOrder?.status ?? "todo"],
          border: `1px solid ${
            ServiceOrderStatusColor[serviceOrder?.status ?? "todo"]
          }`,
          backgroundColor: alpha(
            ServiceOrderStatusColor[serviceOrder?.status ?? "todo"],
            0.3
          ),
        }}
      >
        <div
          className={classes.smallCircle}
          style={{
            backgroundColor:
              ServiceOrderStatusColor[serviceOrder?.status ?? "todo"],
          }}
        />
        {StatusName[serviceOrder?.status ?? "todo"]}
      </div>
    </EuleroTooltip>
  );
};

export default ServiceOrderStatusTag;
