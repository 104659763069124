import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShimmerBox from "../shimmer/ShimmerBox";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    width: "100%",
  },
  label: {
    gap: "10px",
    height: "100%",
    minHeight: "40px",
    width: "140px",
    minWidth: "140px",
    maxWidth: "140px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "transparent",
    borderRadius: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  textField: {
    width: "100%",
    padding: "0.5rem",
    borderRadius: "5px",

    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
}));

const ViewTextField = ({ data, name = null, icon = null }) => {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      <div className={classes.label}>
        {icon && <FontAwesomeIcon icon={icon} color={"#888"} />}
        <label>{name}</label>
      </div>
      {data ? (
        <Typography variant="body1" className={classes.textField}>
          {data}
        </Typography>
      ) : (
        <ShimmerBox width="100%" height="39.9px" />
      )}
    </div>
  );
};

export default ViewTextField;
