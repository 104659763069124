import React from "react";
import { Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  skeleton: {
    // minHeight: "1rem",
    minWidth: "10rem",
    borderRadius: "5px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    animation: "$shimmer 1.5s infinite",
  },
  "@keyframes shimmer": {
    "0%": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    "50%": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    "100%": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
}));

const ShimmerBox = ({ height = 28, width = "100%" }) => {
  const classes = useStyles();
  return (
    <div style={{ height, width }}>
      <Skeleton
        className={classes.skeleton}
        variant="rect"
        width={width}
        height={height}
        maxHeight={height}
        minHeight={height}
      />
    </div>
  );
};

export default ShimmerBox;
