import {
  faArrowsUpToLine,
  faCalendar,
  faIndustry,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableCell, TableHead, TableRow, Typography } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  headerTableCell: {
    gap: "0.5rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textTransform: "uppercase",
  },
}));

const AlertsTableHead = () => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <div className={classes.headerTableCell}>
            <Typography variant="body2" color={"#666"} fontWeight={"bold"}>
              Alerta
            </Typography>
          </div>
        </TableCell>

        <TableCell>
          <div className={classes.headerTableCell}>
            <FontAwesomeIcon icon={faCalendar} color={"#666"} />
            <Typography variant="body2" color={"#666"} fontWeight={"bold"}>
              Data
            </Typography>
          </div>
        </TableCell>

        <TableCell>
          <div className={classes.headerTableCell}>
            <FontAwesomeIcon icon={faArrowsUpToLine} color={"#666"} />
            <Typography variant="body2" color={"#666"} fontWeight={"bold"}>
              Criticidade
            </Typography>
          </div>
        </TableCell>

        <TableCell>
          <div className={classes.headerTableCell}>
            <FontAwesomeIcon icon={faIndustry} color={"#666"} />
            <Typography variant="body2" color={"#666"} fontWeight={"bold"}>
              Ativo
            </Typography>
          </div>
        </TableCell>

        <TableCell>
          <div className={classes.headerTableCell} />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default AlertsTableHead;
