import chroma from "chroma-js";
import { createTheme } from "@mui/material/styles";

// Define your primary color
const primaryColor = "#93278f";
const primaryColorLight = chroma(primaryColor).brighten(1.5).hex();
const primaryColorDark = chroma(primaryColor).darken(1.5).hex();

const secondaryColor = "#662d91";
const secondaryColorLight = chroma(secondaryColor).brighten(1.5).hex();
const secondaryColorDark = chroma(secondaryColor).darken(1.5).hex();

// Primary Color (#93278f) Shades
// 50: #dc3ad6
// 100: #b730b2
// 200: #a12a9d
// 300: #9a2896
// 400: #8b2587
// 500: #842380 (Base Color)
// 600: #751f72
// 700: #581755
// 800: #3a0f39
// 900: #1d071c
// Secondary Color (#662d91) Shades
// 50: #9943d9
// 100: #7f38b5
// 200: #70319f
// 300: #6b2f98
// 400: #602a89
// 500: #5b2882 (Base Color)
// 600: #512474
// 700: #3d1b57
// 800: #28123a
// 900: #14091d

export const tokens = (mode) => {
  return {
    primary: {
      50: "#dc3ad6",
      100: "#b730b2",
      200: "#a12a9d",
      300: "#9a2896",
      400: "#8b2587",
      500: primaryColor,
      600: primaryColorDark,
      700: "#581755",
      800: "#3a0f39",
      900: "#1d071c",
    },
    secondary: {
      50: "#9943d9",
      100: "#7f38b5",
      200: "#70319f",
      300: "#6b2f98",
      400: "#602a89",
      500: secondaryColor,
      600: secondaryColorDark,
      700: "#3d1b57",
      800: "#28123a",
      900: "#14091d",
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
    },
    greenAccent: {
      100: "#dbf5ee",
      200: "#b7ebde",
      300: "#94e2cd",
      400: "#70d8bd",
      500: "#4cceac",
      600: "#3da58a",
      700: "#2e7c67",
      800: "#1e5245",
      900: "#0f2922",
    },
    redAccent: {
      100: "#f8dcdb",
      200: "#f1b9b7",
      300: "#e99592",
      400: "#e2726e",
      500: "#db4f4a",
      600: "#af3f3b",
      700: "#832f2c",
      800: "#58201e",
      900: "#2c100f",
    },
    blueAccent: {
      100: "#e1e2fe",
      200: "#c3c6fd",
      300: "#a4a9fc",
      400: "#868dfb",
      500: "#6870fa",
      600: "#535ac8",
      700: "#3e4396",
      800: "#2a2d64",
      900: "#151632",
    },
    mode: mode,
  };
};

// Create your custom theme
export const lightTheme = createTheme({
  palette: {
    mode: "light", // Switch to 'light' mode
    primary: {
      light: primaryColorLight,
      main: primaryColor,
      dark: primaryColorDark,
    },
    secondary: {
      light: secondaryColorLight,
      main: secondaryColor,
      dark: secondaryColorDark,
    },
    error: {
      light: "#ff6966", // Lighter shade of error color
      main: "#ff0000", // Main error color
      dark: "#cc0000", // Darker shade of error color
    },
    warning: {
      light: "#ffc947", // Lighter shade of warning color
      main: "#ff9800", // Main warning color
      dark: "#d87d00", // Darker shade of warning color
    },
    info: {
      light: "#5a9eff", // Lighter shade of info color
      main: "#03a9f4", // Main info color
      dark: "#0271a3", // Darker shade of info color
    },
    success: {
      light: "#7be077", // Lighter shade of success color
      main: "#4caf50", // Main success color
      dark: "#357e37", // Darker shade of success color
    },
    type: "light", // Use 'light' theme as default
    text: {
      primary: "#000", // Text color for light theme
      secondary: "rgba(0, 0, 0, 0.7)", // Secondary text color
      disabled: "rgba(0, 0, 0, 0.5)", // Disabled text color
    },
    action: {
      active: "#000", // Active action color
      hover: "rgba(0, 0, 0, 0.08)", // Hover action color
      selected: "rgba(0, 0, 0, 0.16)", // Selected action color
      disabled: "rgba(0, 0, 0, 0.3)", // Disabled action color
      disabledBackground: "rgba(0, 0, 0, 0.12)", // Disabled action background
    },
    background: {
      default: "#eef2f6", // Default background color
      paper: "#ffffff", // Paper background color
    },
    divider: "rgba(0, 0, 0, 0.12)", // Divider color
  },
  typography: {
    fontFamily: "Inter, sans-serif", // Use the Inter font

    // Heading styles
    h1: {
      fontSize: "2.5rem",
      fontWeight: 600,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 600,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 600,
    },

    // Subtitle styles
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },

    // Body text styles
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },

    // Button text styles
    button: {
      fontSize: "1rem",
      fontWeight: 600,
      textTransform: "none", // Preserve case in button text
    },

    // Additional styles (customize as needed)
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
    overline: {
      fontSize: "0.625rem",
      fontWeight: 600,
      textTransform: "uppercase",
    },
  },
  shape: {
    borderRadius: 8, // Adjust the default border radius
  },
  card: {
    borderRadius: 8, // Adjust the default border radius
    boxShadow:
      "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
  },

  components: {
    MuiAppBar: {
      defaultProps: {
        enableColorOnDark: true,
      },
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#ffffff",
        },
      },
    },
  },
});

// Create your custom theme
export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      light: primaryColorLight,
      main: primaryColor,
      dark: primaryColorDark,
    },
    secondary: {
      light: secondaryColorLight,
      main: secondaryColor,
      dark: secondaryColorDark,
    },
    error: {
      light: "#ef5350", // Lighter shade of error color
      main: "#d32f2f", // Main error color
      dark: "#c62828", // Darker shade of error color
    },
    warning: {
      light: "#ff9800", // Lighter shade of warning color
      main: "#ed6c02", // Main warning color
      dark: "#e65100", // Darker shade of warning color
    },
    info: {
      light: "#03a9f4", // Lighter shade of info color
      main: "#0288d1", // Main info color
      dark: "#01579b", // Darker shade of info color
    },
    success: {
      light: "#4caf50", // Lighter shade of success color
      main: "#2e7d32", // Main success color
      dark: "#1b5e20", // Darker shade of success color
    },
    type: "dark", // Use 'dark' theme as default
    text: {
      primary: "#fff", // Text color for dark theme
      secondary: "rgba(255, 255, 255, 0.7)", // Secondary text color
      disabled: "rgba(255, 255, 255, 0.5)", // Disabled text color
    },
    action: {
      active: "#fff", // Active action color
      hover: "rgba(255, 255, 255, 0.08)", // Hover action color
      selected: "rgba(255, 255, 255, 0.16)", // Selected action color
      disabled: "rgba(255, 255, 255, 0.3)", // Disabled action color
      disabledBackground: "rgba(255, 255, 255, 0.12)", // Disabled action background
    },
    background: {
      default: "#121212", // Default background color
      paper: "#121212", // Paper background color
    },
    divider: "rgba(255, 255, 255, 0.12)", // Divider color
  },
  typography: {
    fontFamily: "Inter, sans-serif", // Use the Inter font

    // Heading styles
    h1: {
      fontSize: "2.5rem",
      fontWeight: 600,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 600,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 600,
    },

    // Subtitle styles
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },

    // Body text styles
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },

    // Button text styles
    button: {
      fontSize: "1rem",
      fontWeight: 600,
      textTransform: "none", // Preserve case in button text
    },

    // Additional styles (customize as needed)
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
    overline: {
      fontSize: "0.625rem",
      fontWeight: 600,
      textTransform: "uppercase",
    },
  },
  shape: {
    borderRadius: 8, // Adjust the default border radius
  },
  card: {
    borderRadius: 8, // Adjust the default border radius
    boxShadow: "0px 0px 80px 0px rgba(0,0,0,0.2)", // Add a soft shadow
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        enableColorOnDark: true,
      },
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#121212",
        },
      },
    },
  },
});
