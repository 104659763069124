import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignal5 } from "@fortawesome/free-solid-svg-icons";
import { DeviceStatusColor } from "../../../theme/StatusColor";
import ShimmerBox from "../shimmer/ShimmerBox";
import EuleroTooltip from "../Tooltip";
import DeviceService from "../../../api/services/devices";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    gap: theme.spacing(2),
    width: "100%",
  },
  label: {
    gap: "10px",
    height: "100%",
    minHeight: "40px",
    width: "140px",
    minWidth: "140px",
    maxWidth: "140px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "transparent",
    borderRadius: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  smallCircle: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
  },
  section: {
    width: "100%",
    padding: "0.5rem",
    borderRadius: "5px",
    backgroundColor: "transparent",
    border: `1px solid transparent`,
    transition: "all 0.1s ease-in-out",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },

  online: {
    "&:hover": {
      border: `1px solid ${DeviceStatusColor["online"]}`,
    },
  },
  offline: {
    "&:hover": {
      border: `1px solid ${DeviceStatusColor["offline"]}`,
    },
  },
  setup: {
    "&:hover": {
      border: `1px solid ${DeviceStatusColor["setup"]}`,
    },
  },
  "no-response": {
    "&:hover": {
      border: `1px solid ${DeviceStatusColor["no-response"]}`,
    },
  },
}));

const DeviceViewTextField = ({ asset_id }) => {
  const classes = useStyles();

  const [device, setDevice] = React.useState(null);

  React.useEffect(() => {
    if (asset_id !== null) {
      const deviceService = new DeviceService();
      deviceService
        .getList({ assetId: asset_id })
        .then((response) => {
          setDevice(response.data.results[0]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  });


  return (
    <div className={classes.row}>
      <div className={classes.label}>
        <FontAwesomeIcon icon={faSignal5} color={"#888"} />
        <label>Dispositivo</label>
      </div>
      {device !== null && device?.status !== null ? (
        <EuleroTooltip
          title={`Dispositivo ${device?.identifier} - ${device?.status}`}
          placement="top"
        >
          <div
            className={`${classes.section} ${classes[device?.status ?? "offline"]
              }`}
          >
            <div className={classes.row}>
              <div
                className={classes.smallCircle}
                style={{
                  backgroundColor:
                    DeviceStatusColor[device?.status ?? "offline"],
                }}
              />
              <Typography variant="body1">
                {`Dispositivo ${device?.identifier}`}
              </Typography>
            </div>
          </div>
        </EuleroTooltip>
      ) : (
        <ShimmerBox width="100%" height="39.9px" />
      )}
    </div>
  );
};

export default DeviceViewTextField;
