import { NumericFormat } from "react-number-format";

export function ProcessVibrationData(vibrationData) {
  const data = [];

  ["ax", "ay", "az"].forEach((axis) => {
    const axisValues = [];
    for (let i = 1; i < vibrationData.frequencies.length; i++) {
      // for (let i = 1; i < 100; i++) {
      axisValues.push({
        x: vibrationData.frequencies[i],
        y: vibrationData[axis][i],
      });
    }
    const axisData = { id: axis, data: axisValues };
    data.push(axisData);
  });

  return data;
}

export function FormatFrequency({ value }) {
  /// <summary>
  ///
  /// <summary>
  /// <param name="value">Number to format</param>
  /// <returns>Formatted number</returns>

  return (
    <NumericFormat
      value={
        value >= 1000
          ? value / 1000
          : value >= 1000000
          ? value / 1000000
          : value
      }
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={2}
      suffix={
        value >= 1000000000
          ? "B"
          : value >= 1000000
          ? "M"
          : value >= 1000
          ? "K"
          : ""
      }
    />
  );
}

export function FormatIntesity({ value }) {
  /// <summary>
  ///
  /// <summary>
  /// <param name="value">Number to format</param>
  /// <returns>Formatted number</returns>

  return (
    <NumericFormat
      value={
        value >= 1000
          ? value / 1000
          : value >= 1000000
          ? value / 1000000
          : value
      }
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={2}
      suffix={
        value >= 1000000000
          ? "B"
          : value >= 1000000
          ? "M"
          : value >= 1000
          ? "K"
          : ""
      }
    />
  );
}
