import React from "react";
import { TableRow, TableCell, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  skeleton: {
    minHeight: "1rem",
    minWidth: "10rem",
    borderRadius: "5px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    animation: "$shimmer 1.5s infinite",
  },
  "@keyframes shimmer": {
    "0%": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    "50%": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    "100%": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
}));

const ShimmerRow = ({ numberOfCols, width = "100%" }) => {
  const classes = useStyles();
  return (
    <TableRow>
      {Array(numberOfCols)
        .fill()
        .map((_, index) => (
          <TableCell key={index}>
            <Skeleton
              className={classes.skeleton}
              variant="rect"
              width={width}
              height={28}
            />
          </TableCell>
        ))}
    </TableRow>
  );
};

export default ShimmerRow;
