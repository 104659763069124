import React from "react";
import { Button, Typography, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid " + theme.palette.primary.main,
    padding: "20px",
    marginTop: "50px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "start",
    gap: "2rem",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  icon: {
    color: theme.palette.primary.main,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "8px",
    padding: "20px",
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    alignContent: "center",
  },
  title: {
    color: theme.palette.primary.main,
  },
}));

const NoUserCard = ({ onAddUserClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <div className={classes.row}>
        <div className={classes.iconContainer}>
          <FontAwesomeIcon
            className={classes.icon}
            icon={faUserPlus}
            size="3x"
          />
        </div>
        <div className={classes.column}>
          <Typography variant="h5" component="div" className={classes.title}>
            Primeiro acesso?
          </Typography>
          <Typography variant="body2">
            Parece que você ainda não criou nenhum usuário para sua conta. Para
            que outras pessoas possam acessar a plataforma, você precisa criar
            usuários para elas.
          </Typography>
          <Typography variant="body2">
            Aqui está uma breve explicação de cada função de usuário que você
            pode criar na plataforma:
            <ul>
              <li>
                <strong>Visualização:</strong> Pode apenas visualizar dados
                dentro da plataform mas sem poder fazer alterações. Normalmente
                atribuido a supervisores e gerentes de operações.
              </li>

              <li>
                <strong>Manutenção:</strong> Tem todas as permissões de
                visualização e pode atualizar, avaliar e classificar ordens de
                serviço. Normalmente atribuido a técnicos de manutenção e
                engenheiros de manutenção.
              </li>

              <li>
                <strong>Gerente de Manutenção:</strong> Tem todas as permissões
                de Manutenção e pode fazer criação, fechamento, repriorização e
                reatribuição de ordens de serviço. Normalmente atribuido a
                gerentes de manutenção e engenheiros de manutenção.
              </li>

              <li>
                <strong>Administrador:</strong> Pode gerenciar todos os aspectos
                da plataforma, com todas as permissões do acesso de Gerente de
                Manutenção, incluindo a capacidade de gestão de usuários,
                dispositivos e ordens de serviço. Normalmente atribuido a
                pessoas de TI e gerentes de operações.
              </li>
            </ul>
          </Typography>
          <Typography variant="body2">
            Após a criação de um usuário, ele tem 24 horas para acessar a
            plataforma e fazer o primeiro login. Durante o primeiro acesso, o
            usuário deve acessar a tela de login e inserir o email cadastrado a
            senha que deseja utilizar. Após este primeiro acesso, a senha será
            salva para ser usada novamente no futuro. Se eles não fizerem o
            primeiro login dentro do período de 24 horas, eles deverão pedir ao
            administrador para permitir/renovar seu acesso.
          </Typography>
        </div>
      </div>
      <div
        className={classes.row}
        style={{
          gap: "30px",
          marginTop: "30px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="text"
          color="primary"
          startIcon={<FontAwesomeIcon icon={faUserPlus} />}
          onClick={onAddUserClick}
        >
          Criar Primeiro Usuário
        </Button>
      </div>
    </div>
  );
};

export default NoUserCard;
