import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Button, CircularProgress, Typography } from "@mui/material";
import DeviceConnectionCard from "../../assets/DeviceConnectionCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import DeviceService from "../../../../api/services/devices";

const useStyles = makeStyles((theme) => ({
  column: {
    gap: "1rem",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
  },
  rowEnd: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
  },
}));

const TutorialStepTwo = ({ user, asset: initialAsset, callback }) => {
  const classes = useStyles();
  const [asset, setAsset] = useState(initialAsset);
  const [device, setDevice] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const handleChange = (event) => {
    setIsChanged(true);
    setDevice(event.target.value);
  };

  useEffect(() => setAsset(initialAsset), [initialAsset]);

  const handleClick = async () => {
    let deviceService = new DeviceService();

    try {
      setLoading(true);
      await deviceService.attachToAsset(device.device_id, asset.asset_id);
      callback?.call();
      setIsChanged(false);
    } catch (error) {
      toast.error("Erro ao atualizar ativo");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.column}>
      <div className={classes.column}>
        <div className={classes.row}>
          <div className={classes.column}>
            <Typography variant="h5">Conecte um dispositivo</Typography>
            <Typography variant="body1">
              Conecte um dispositivo para começar a monitorar e gerenciar as a
              saúde do seu ativo em campo.
            </Typography>
            <Typography variant="body1">
              Assim que o dispositivo estiver conectado, clique em continuar
              para prosseguir. E caso tenha algum problema, entre em contato com
              o suporte da Eulera.
            </Typography>
          </div>
          <div className={classes.column}>
            <DeviceConnectionCard asset={asset} callback={handleChange} />
          </div>
        </div>
      </div>

      <div className={classes.rowEnd} style={{ justifyContent: "end" }}>
        {user?.company?.tutorial_progress === "2 step" ? (
          <>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleClick}
              disabled={!isChanged || isLoading}
              startIcon={isLoading && <CircularProgress size={16} />}
            >
              Continuar
            </Button>
          </>
        ) : (
          <>
            <Button
              color="success"
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
            >
              Dispositivo conectado
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default TutorialStepTwo;
