import React from "react";
import { makeStyles } from "@mui/styles";
import { ResponsiveBar } from "@nivo/bar";
import { Typography } from "@mui/material";
import { generateAlertsTimeBarChartData } from "../functions/GenerateData";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    display: "flex",
    color: "black",
    padding: "0.5rem",
    borderRadius: "5px",
    flexDirection: "column",
    backgroundColor: "white",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
    border: `1px solid ${theme.palette.divider}`,
  },
}));

const AlertsTimeBarChart = ({ alerts }) => {
  const classes = useStyles();
  const data = generateAlertsTimeBarChartData(alerts);
  const maxValue = Math.max(...data.map((d) => d.alerts));

  return (
    <div
      style={{
        height: "150px",
        width: "100%",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      }}
    >
      <ResponsiveBar
        data={data}
        indexBy="date"
        keys={["alerts"]}
        enableGridX={false}
        enableGridY={false}
        maxValue={maxValue < 5 ? 5 : maxValue + 10}
        margin={{ top: 10, right: 4, bottom: 30, left: 4 }}
        colors={({ id }) => (id === "alerts" ? "#cf0000" : "#ccc")}
        tooltip={({ id, value, indexValue }) => (
          <div className={classes.tooltip}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {value} alertas
            </Typography>
            <Typography variant="caption">{indexValue}</Typography>
          </div>
        )}
        axisTop={null}
        axisLeft={null}
        axisRight={null}
        axisBottom={{
          tickSize: 3,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: 32,
          format: (value) => {
            const datePattern = /^(\d{2}\/\d{2}) 00:00/; // Matches "dd/mm 00:00"
            const match = value.match(datePattern);
            return match ? match[1] : "";
          },
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        role="img"
        ariaLabel="Gráfico de barras de alertas por data"
      />
    </div>
  );
};

export default AlertsTimeBarChart;
