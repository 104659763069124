import React from "react";
import { makeStyles } from "@mui/styles";
import AlertsTableSection from "../../global/AlertsTableSection/AlertsTableSection";
import AlertDashboardSection from "../../global/AlertsDashboardSection/AlertsDashboardSection";

const useStyles = makeStyles((theme) => ({
  column: {
    gap: "1rem",
    height: "100%",
    display: "flex",
    padding: "3rem",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
  },
}));

const AlertsPage = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.column}>
        <AlertDashboardSection />

        <AlertsTableSection />
      </div>
    </>
  );
};

export default AlertsPage;
