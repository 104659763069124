import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { AlertCriteriaColor } from "../../../theme/StatusColor";
import ShimmerBox from "../shimmer/ShimmerBox";
import EuleroTooltip from "../Tooltip";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    gap: theme.spacing(2),
    width: "100%",
  },
  label: {
    gap: "10px",
    height: "100%",
    minHeight: "40px",
    width: "140px",
    minWidth: "140px",
    maxWidth: "140px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "transparent",
    borderRadius: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  smallCircle: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
  },
  section: {
    width: "100%",
    padding: "0.5rem",
    borderRadius: "5px",
    backgroundColor: "transparent",
    border: `1px solid transparent`,
    transition: "all 0.1s ease-in-out",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },

  "not-a-problem": {
    "&:hover": {
      border: `1px solid ${AlertCriteriaColor["not-a-problem"]}`,
    },
  },
  maintenance: {
    "&:hover": {
      border: `1px solid ${AlertCriteriaColor["maintenance"]}`,
    },
  },
  "possible-issue": {
    "&:hover": {
      border: `1px solid ${AlertCriteriaColor["possible-issue"]}`,
    },
  },
}));

const AlertViewTextField = ({ alert, isEmpty = false }) => {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <div className={classes.label}>
        <FontAwesomeIcon icon={faExclamationTriangle} color={"#888"} />
        <label>Alerta</label>
      </div>
      {alert !== null && alert?.criteria !== null ? (
        <EuleroTooltip title={`${alert?.created}`} placement="top">
          <div
            className={`${classes.section} ${
              classes[alert?.criteria ?? "not-a-problem"]
            }`}
          >
            <div className={classes.row}>
              <div
                className={classes.smallCircle}
                style={{
                  backgroundColor:
                    AlertCriteriaColor[alert?.criteria ?? "not-a-problem"],
                }}
              />
              <Typography variant="body1">{alert?.title}</Typography>
            </div>
          </div>
        </EuleroTooltip>
      ) : isEmpty ? (
        <div className={classes.section}>
          <Typography variant="body1" fontStyle={{ fontStyle: "italic" }}>
            Nenhum alerta
          </Typography>
        </div>
      ) : (
        <ShimmerBox width="100%" height="39.9px" />
      )}
    </div>
  );
};

export default AlertViewTextField;
