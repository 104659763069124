import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { ResponsiveLine } from "@nivo/line";
import {
  ProcessVibrationData,
  FormatFrequency,
  FormatIntesity,
} from "./functions/processVibrationData";

const useStyles = makeStyles((theme) => {
  return {
    column: {
      gap: "1rem",
      padding: "0",
      flexDirection: "column",
      overflowY: "auto",
    },
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
      alignItems: "center",
    },
    tooltip: {
      padding: "0.5rem",
      borderRadius: "5px",
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
      zIndex: 1000000,
    },
  };
});

const VibrationSpectrogram = ({ vibrationData }) => {
  const classes = useStyles();
  const data = ProcessVibrationData(vibrationData);
  const [highlightedId, setHighlightedId] = useState(null);

  const handleMouseEnter = (line) => setHighlightedId(line.id);
  const handleMouseLeave = () => setHighlightedId(null);

  const tooltip = ({ point }) => {
    return (
      <div className={classes.tooltip}>
        <strong>Intensidade:</strong> <FormatIntesity value={point.data.y} />
        <br />
        <strong>Frequência (Hz):</strong>{" "}
        <FormatFrequency value={point.data.x} /> Hz
      </div>
    );
  };

  const legends = [
    {
      anchor: "top",
      direction: "row",
      justify: false,
      translateX: 30,
      translateY: -40,
      itemsSpacing: 0,
      itemDirection: "left-to-right",
      itemWidth: 80,
      itemHeight: 0,
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolBorderColor: "rgba(0, 0, 0, .5)",
      itemTextColor: ({ id }) => (id === highlightedId ? "inherit" : "#ddd"),
    },
  ];

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 80, right: 0, bottom: 60, left: 0 }}
      xScale={{
        type: "linear",
        min: 10,
        max: vibrationData.frequencies.reduce((a, b) => Math.max(a, b)),
      }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
      }}
      yFormat=" >-.2f"
      colors={{ scheme: "dark2" }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Frequência (Hz)",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={null}
      enableGridX={false}
      enableGridY={false}
      enablePoints={false}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={3}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      enableTouchCrosshair={true}
      useMesh={true}
      tooltip={tooltip}
      layers={[
        "grid",
        "axes",
        "areas",
        "crosshair",
        "lines",
        "points",
        "slices",
        "mesh",
        "legends",
      ]}
      lineOpacity={(line) => (line.id === highlightedId ? 1 : 0.3)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      legends={legends}
    />
  );
};

export default VibrationSpectrogram;
