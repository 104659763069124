import React from "react";

import { Autocomplete, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    width: "100%",
  },
  label: {
    gap: "10px",
    height: "100%",
    minHeight: "39.9px",
    maxHeight: "40px",
    width: "140px",
    minWidth: "140px",
    maxWidth: "140px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // fontSize: "0.8rem",
    backgroundColor: "transparent",
    borderRadius: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  labelSuper: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  textField: {
    width: "100%",
    height: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      "&:hover": {
        backgroundColor: "#eee",
      },
      "&.Mui-focused": {
        borderColor: "transparent",
        backgroundColor: "#eee",
        "& fieldset": {
          borderWidth: 0,
          notched: false,
        },
      },
      "&.Mui-disabled": {
        backgroundColor: "#ddd",
        borderWidth: 0,
        notched: false,
      },
      "& fieldset": {
        borderWidth: 0,
        notched: false,
      },
    },
  },
  highlighted: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#eee",
    },
  },
  textFieldSuper: {
    borderRadius: "5px",
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const SelectingTextField = ({
  data,
  size,
  onChange,
  onFinished = null,
  name = null,
  fieldId = null,
  label = "name",
  options = [],
  icon = null,
  freeSolo = false,
  showError = false,
  errorMessage = null,
  highlighted = false,
  mustHaveOptions = true,
  superHighlighted = false,
}) => {
  const classes = useStyles();
  const [value] = React.useState(data);

  return (
    <div className={classes.row}>
      <div
        className={`${classes.label} ${
          superHighlighted ? classes.labelSuper : ""
        } `}
      >
        {icon && <FontAwesomeIcon icon={icon} color={"#888"} />}
        <label>{name}</label>
      </div>
      <Autocomplete
        id={fieldId}
        options={options}
        className={`${classes.textField} ${
          highlighted ? classes.highlighted : ""
        } ${superHighlighted ? classes.textFieldSuper : ""}`}
        getOptionLabel={(option) => {
          if (typeof option === "string") return option;
          return option ? option[label ?? "name"] : "";
        }}
        value={value ?? ""}
        disabled={mustHaveOptions && options.length === 0}
        onInputChange={(event, newInputValue) => {
          if (onFinished) {
            let value = newInputValue;
            if (typeof newInputValue === "string") {
              value = newInputValue !== "" ? { name: newInputValue } : null;
            }
            onFinished({
              target: {
                name: fieldId,
                value: value,
              },
            });
          }
        }}
        freeSolo={freeSolo}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            // label="Brand"
            variant="outlined"
            size={size ?? "small"}
            error={showError && errorMessage !== null && !value}
            required={errorMessage !== null}
            helperText={showError && !value ? errorMessage : null}
          />
        )}
      />
    </div>
  );
};

export default SelectingTextField;
