import React from "react";

import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    width: "100%",
  },
  textField: {
    width: "100%",
    height: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      "&:hover": {
        backgroundColor: "#eee", // Cor de fundo quando o mouse passa sobre o TextField
      },
      "&.Mui-focused": {
        borderColor: "transparent", // Cor da borda quando o mouse passa sobre o TextField
        backgroundColor: "#eee", // Cor de fundo quando o TextField está sendo editado
        "& fieldset": {
          borderWidth: 0, // Remove a borda
          notched: false, // Remove o entalhe
        },
      },
      "&.Mui-disabled": {
        backgroundColor: "#ddd", // Cor de fundo quando o TextField está desabilitado
        borderWidth: 0, // Remove a borda
        notched: false, // Remove o entalhe
      },
      "& fieldset": {
        borderWidth: 0, // Remove a borda
        notched: false, // Remove o entalhe
      },
    },
  },
}));

const EditingTextField = ({
  data,
  size,
  onChange,
  hint = null,
  name = null,
  showError = false,
  minLines = 1,
  multiline = false,
  higlighted = false,
  errorMessage = null,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(data);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event);
  };

  return (
    <div className={classes.row}>
      <TextField
        name={name}
        size={size ?? "small"}
        variant="outlined"
        className={classes.textField}
        defaultValue={value}
        multiline={multiline}
        minRows={minLines}
        onChange={handleChange}
        placeholder={hint}
        error={showError && errorMessage !== null && !value}
        required={errorMessage !== null}
        helperText={showError && !value ? errorMessage : null}
        InputProps={{
          disableUnderline: true,
          style: {
            backgroundColor: higlighted ? "#f5f5f5" : "transparent",
          },
          inputProps: multiline
            ? {
                style: {
                  maxHeight: "100px",
                  overflow: "auto",
                  fontSize: "0.9rem",
                  fontStyle: "italic",
                },
              }
            : {},
        }}
      />
    </div>
  );
};

export default EditingTextField;
