import { Button, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import SelectingTextField from "../form/SelectingTextField";
import AlertService from "../../../api/services/alerts";
import { toast } from "react-toastify";
import { faClipboardQuestion, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => {
  return {
    column: {
      gap: "1rem",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    textField: {
      width: "100%",
      padding: "1rem",
      borderRadius: "5px",
      backgroundColor: "#f5f5f5",
      justifyContent: "center",
      textAlign: "center",
    },
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
      alignItems: "center",
    },
    card: {
      gap: "1rem",
      display: "flex",
      flexDirection: "column",
      padding: "1rem",
      borderRadius: "10px",
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.shadows[0],
    },
    description: {
      width: "100%",
      padding: "1rem",
      borderRadius: "5px",
      backgroundColor: "#f5f5f5",
    },
  };
});

const ResolutionSection = ({ alert, callback }) => {
  const classes = useStyles();
  const alertService = new AlertService();
  const [reasons, setReasons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userUpdated, setUserUpdated] = useState(false);
  const [currentReason, setCurrentReason] = useState(null);

  useEffect(() => {
    const alertService = new AlertService();
    alertService
      .getReasons()
      .then((response) => {
        setReasons(response.data.results);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Erro ao buscar motivos de alerta");
      });
  }, [alert]);

  useEffect(() => {
    if (alert && alert?.problem) {
      setCurrentReason(alert.problem);
      console.log("alert", alert.problem);
    }
  }, [alert]);

  const handleUpdate = (event) => {
    console.log("handleUpdate", event.target.value);
    setUserUpdated(true);
    setCurrentReason(event.target.value);
  };

  const handleSave = () => {
    console.log("handleSave", currentReason);
    setLoading(true);
    alertService
      .updateField(alert.alert_id, "problem", currentReason)
      .then((response) => {
        toast.success("Motivo do alerta definido com sucesso");
        setUserUpdated(false);
        callback?.();
      })
      .catch((error) => {
        console.error(error);
        toast.error("Erro ao definir motivo do alerta");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classes.column}>
      <Typography variant="body2" className={classes.description}>
        Defina o motivo do alerta para que possamos utilizar como referencia
        para alertas futuros e aprender o evento que gerou o alerta.
      </Typography>

      <SelectingTextField
        size={"small"}
        name={"Motivo"}
        fieldId={"reasons"}
        icon={faClipboardQuestion}
        data={
          alert && alert?.problem
            ? alert.problem?.name
            : null
        }
        options={reasons}
        freeSolo={true}
        highlighted={false}
        superHighlighted={true}
        mustHaveOptions={false}
        onChange={handleUpdate}
        onFinished={handleUpdate}
      />

      <div
        className={classes.row}
        style={{
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <Button
          color="primary"
          variant="outlined"
          onClick={handleSave}
          disabled={!userUpdated || loading}
          startIcon={
            loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <FontAwesomeIcon icon={faSave} />
            )
          }
        >
          Salvar
        </Button>
      </div>
    </div>
  );
};

export default ResolutionSection;
