import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import SideViewer from "../SideViewer";
import { CircularProgress, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import EditingTextField from "../form/EditingTextField";
import AssetHealthTag from "./components/AssetHealthTag";
import AssetStateTag from "./components/AssetStateTag";
// import MaintenanceStatusTag from "./components/MaintenanceStatusTag";
import AssetService from "../../../api/services/assets";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faBullseye,
  faCalendarAlt,
  faCopyright,
  faFont,
  faLocation,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import FormEditingTextField from "../form/FormEditingTextField";
import SelectingTextField from "../form/SelectingTextField";
import EuleroTooltip from "../Tooltip";
import DeviceConnectionCard from "./DeviceConnectionCard";
import AlertsSection from "./AlertsSection";
import AssetVibrationPatternSection from "./AssetVibrationPatternSection";
import { formatDateTime } from "../../../functions/dataFormat";

const useStyles = makeStyles((theme) => ({
  mainColumn: {
    gap: "1rem",
    height: "100%",
    display: "flex",
    padding: "3rem",
    flexDirection: "column",
    overflowY: "auto",
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
  },
  minimalWidthRow: {
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem",
    alignItems: "center",
  },
  smallCircle: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    backgroundColor: "#ddd",
  },
  savingSection: {
    gap: "10px",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    padding: "1rem",
  },
}));

const AssetView = ({
  asset: initialAsset,
  isOpen,
  onClose,
  onSubmitCallback,
}) => {
  const classes = useStyles();
  const [asset, setAsset] = useState(initialAsset);
  const [assetModels, setAssetModels] = useState([]);
  const [assetBrands, setAssetBrands] = useState([]);
  const [isNewAsset, setIsNewAsset] = useState(!initialAsset);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAsset(initialAsset);
    const isNew = initialAsset === null;
    setIsNewAsset(isNew);

    if (isNew) {
      let assetService = new AssetService();
      let newAsset = assetService.getEmpty();
      setAsset(newAsset);
    }
  }, [initialAsset]);

  const handleChange = (event) => {
    // The device component is a standalone component that doesn't need to be saved
    // It's only used to connect the asset to a device and it's saved in the parent component
    if (event.target.name === "device") {
      onSubmitCallback();
      return;
    }
    // The asset component is a standalone component that needs to be saved
    // It's used to create or update an asset
    setIsChanged(true);
    setAsset({
      ...asset,
      [event.target.name]: event.target.value,
    });
  };

  // const handleRevoke = async () => {};

  const handleSave = async () => {
    let assetService = new AssetService();
    const validation = assetService.validateAsset(asset);

    if (validation) {
      toast.error(`${validation["message"]}`);
      return;
    }

    if (isNewAsset) {
      try {
        setLoading(true);
        const response = await assetService.create(asset);
        toast.success("Ativo criado com sucesso");
        onSubmitCallback(response.data);
        setIsChanged(false);
      } catch (error) {
        console.log(error);
        toast.error("Erro ao criar ativo");
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        let assetService = new AssetService();
        const response = await assetService.update(asset.asset_id, asset);
        toast.success("Ativo atualizado com sucesso");
        onSubmitCallback(response.data);
        setIsChanged(false);
      } catch (error) {
        console.log(error);
        toast.error("Erro ao atualizar ativo");
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchAssetModels = async () => {
    try {
      let assetService = new AssetService();
      const response = await assetService.getModels();
      setAssetModels(response.data["results"]);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao buscar modelos de ativos");
    }
  };

  const fetchAssetBrands = async () => {
    try {
      let assetService = new AssetService();
      const response = await assetService.getBrands();
      setAssetBrands(response.data["results"]);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao buscar marcas de ativos");
    }
  };

  useEffect(() => {
    fetchAssetModels();
  }, []);

  useEffect(() => {
    fetchAssetBrands();
  }, []);

  return (
    <SideViewer isOpen={isOpen} onClose={onClose} minWidth="600px">
      <div className={classes.mainColumn}>
        {isNewAsset && (
          <Typography variant="h4" style={{ marginBottom: "1rem" }}>
            Novo ativo
          </Typography>
        )}

        {/* Asset Name */}
        <div className={classes.row}>
          <FontAwesomeIcon icon={faBullseye} color={"#888"} />
          <EditingTextField
            name={"name"}
            hint={isNewAsset ? "Nome do ativo" : null}
            data={asset && asset.name ? asset.name : ""}
            onChange={handleChange}
          />
        </div>

        {/* Asset tags */}
        {!isNewAsset && (
          <div className={classes.row}>
            <AssetHealthTag asset={asset} />
            <AssetStateTag asset={asset} />
            {/* <MaintenanceStatusTag asset={asset} /> */}
          </div>
        )}

        <EditingTextField
          name={"description"}
          hint={isNewAsset ? "Descrição do ativo" : null}
          data={asset && asset.description ? asset.description : ""}
          multiline={true}
          onChange={handleChange}
        />

        {!isNewAsset && (
          <div className={classes.row}>
            {/* Asset created by */}
            <div className={classes.smallCircle} />

            {asset && asset.created_at && (
              <EuleroTooltip title={"Criado em"}>
                <div className={classes.minimalWidthRow}>
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    color={"#bdbdbd"}
                    style={{ fontSize: "15px" }}
                  />
                  <Typography variant="caption">
                    {asset && asset.created_at
                      ? `${formatDateTime(asset.created_at)}`
                      : ""}
                  </Typography>
                </div>
              </EuleroTooltip>
            )}

            <div className={classes.smallCircle} />
          </div>
        )}

        <Divider />

        {/* Asset nickname */}
        <FormEditingTextField
          name="Código"
          fieldId={"nickname"}
          icon={faTag}
          hint={isNewAsset ? "Código do ativo" : null}
          data={asset && asset ? asset.nickname : ""}
          onChange={handleChange}
        />

        {/* Asset serial number */}
        <FormEditingTextField
          name="Serial"
          fieldId={"serial_number"}
          icon={faBarcode}
          hint={isNewAsset ? "Número serial do ativo" : null}
          data={asset && asset ? asset.serial_number : ""}
          onChange={handleChange}
        />

        {/* Asset location */}
        <FormEditingTextField
          name="Fabricação"
          fieldId={"year"}
          icon={faCalendarAlt}
          hint={isNewAsset ? "Ano de fabricação do ativo" : null}
          data={asset && asset ? asset.year : ""}
          onChange={handleChange}
        />

        {/* Asset location */}
        <FormEditingTextField
          name="Localização"
          fieldId={"location"}
          icon={faLocation}
          hint={isNewAsset ? "Localização do ativo" : null}
          data={asset && asset ? asset.location : ""}
          onChange={handleChange}
        />

        <Divider />

        {/* Asset brands */}
        <SelectingTextField
          name={"Marca"}
          fieldId={"brand"}
          icon={faCopyright}
          data={asset && asset.brand ? asset.brand : null}
          options={assetBrands}
          freeSolo={true}
          highlighted={false}
          superHighlighted={false}
          mustHaveOptions={false}
          onChange={handleChange}
          onFinished={handleChange}
        />

        {/* Asset models */}
        <SelectingTextField
          name={"Modelo"}
          fieldId={"model"}
          icon={faFont}
          data={asset && asset.model ? asset.model : null}
          options={assetModels}
          freeSolo={true}
          highlighted={false}
          superHighlighted={false}
          mustHaveOptions={false}
          onChange={handleChange}
          onFinished={handleChange}
        />

        {!isNewAsset && (
          <DeviceConnectionCard asset={asset} callback={handleChange} />
        )}

        {!isNewAsset && (
          <>
            <Divider />
            <AssetVibrationPatternSection asset={asset} />
          </>
        )}

        {!isNewAsset && (
          <>
            <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />
            <AlertsSection asset={asset} onParentClose={onClose} />
          </>
        )}

        <div style={{ height: "2rem" }} />
      </div>
      <div className={classes.savingSection}>
        <Divider />

        <div
          className={classes.row}
          style={{
            gap: "30px",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Button variant="text" color="primary" onClick={onClose}>
            Cancelar
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={handleSave}
            disabled={(!isNewAsset && !isChanged) || loading}
            startIcon={loading && <CircularProgress size={16} />}
          >
            {!isNewAsset ? "Salvar" : "Criar ativo"}
          </Button>
        </div>
      </div>
    </SideViewer>
  );
};

export default AssetView;
