import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const EuleroTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    PopperProps={{
      style: { zIndex: 99999999999999 },
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87) !important",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2) !important",
    border: "0px solid #dadde9 !important",
    fontSize: 11,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    textAlign: "center",
  },
}));

export default EuleroTooltip;
