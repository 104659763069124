import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullseye,
  faCalendarCheck,
  faHelmetSafety,
  faPlusCircle,
  faSave,
  faSquareCaretUp,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import ServiceOrderService from "../../../api/services/serviceOrders";
import EditingTextField from "../form/EditingTextField";
import SelectingTextField from "../form/SelectingTextField";
import DatePickerFormField from "../form/DatePickerFormField";
import AssetService from "../../../api/services/assets";
import AlertViewTextField from "../form/AlertViewTextField";
import UserService from "../../../api/services/users";

const useStyles = makeStyles((theme) => ({
  mainColumn: {
    gap: "1rem",
    height: "100%",
    display: "flex",
    padding: "3rem",
    flexDirection: "column",
    overflowY: "auto",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
  },
  rowEnding: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
  },
  evidenceImage: {
    padding: "1rem",
    borderRadius: "5px",
    border: "1px solid #ddd",
  },

  minimalWidthRow: {
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem",
    alignItems: "center",
  },
  smallCircle: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    backgroundColor: "#ddd",
  },
  savingSection: {
    gap: "10px",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    padding: "1rem",
  },
  textField: {
    width: "100%",
    padding: "1rem",
    borderRadius: "5px",
    backgroundColor: "#f5f5f5",
  },
}));

const StatusName = {
  todo: "Aberto",
  "in-progress": "Em progresso",
  closed: "Fechado",
};

const PriorityName = {
  low: "Baixa",
  medium: "Média",
  high: "Alta",
};

const ServiceOrderBody = ({
  serviceOrder: initialServiceOrder,
  isFromModal = false,
  onClose,
}) => {
  let usersTimeoutId = null;
  let assetsTimeoutId = null;
  const classes = useStyles();
  const [isNewServiceOrder, setIsNewServiceOrder] = useState(true);
  const [serviceOrder, setServiceOrder] = useState(initialServiceOrder);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState(
    initialServiceOrder &&
      initialServiceOrder?.assets &&
      initialServiceOrder?.assets?.length > 0
      ? [initialServiceOrder.assets[0]]
      : null
  );
  const [users, setUsers] = useState(
    initialServiceOrder &&
      initialServiceOrder?.assigned &&
      initialServiceOrder?.assigned?.length > 0
      ? [initialServiceOrder.assigned[0]]
      : null
  );

  const fetchAvailableAssets = () => {
    clearTimeout(assetsTimeoutId);
    assetsTimeoutId = setTimeout(() => {
      let assetService = new AssetService();
      assetService
        .getList(1, 100)
        .then((response) => {
          let newAssetList = response.data["results"];
          setAssets(newAssetList);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Erro ao buscar dispositivos disponíveis.");
        });
    }, 1000);
  };

  const fetchAvailableUsers = () => {
    clearTimeout(usersTimeoutId);
    usersTimeoutId = setTimeout(() => {
      let userService = new UserService();
      userService
        .getList(1, 100)
        .then((response) => {
          let newUsersList = response.data["results"];
          setUsers(newUsersList);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Erro ao buscar usuários disponíveis.");
        });
    }, 1000);
  };

  useEffect(() => {
    fetchAvailableAssets();
    return () => clearTimeout(assetsTimeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAvailableUsers();
    return () => clearTimeout(usersTimeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setServiceOrder(null);
    onClose?.call();
  };

  const handleSave = async () => {
    setLoading(true);
    const serviceOrderService = new ServiceOrderService();
    const error = serviceOrderService.validateServiceOrder(serviceOrder);
    console.log(serviceOrder);
    if (error) {
      toast.error(`${error.message}`);
      setLoading(false);
      return;
    }

    if (isNewServiceOrder) {
      serviceOrderService
        .create(serviceOrder)
        .then((response) => {
          toast.success("Ordem de serviço criada com sucesso");
          setServiceOrder(response.data);
          handleClose();
        })
        .catch((error) => {
          console.error(error);
          toast.error("Erro ao criar ordem de serviço");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      serviceOrderService
        .update(serviceOrder.id, serviceOrder)
        .then((response) => {
          toast.success("Ordem de serviço criada com sucesso");
          setServiceOrder(response.data);
          handleClose();
        })
        .catch((error) => {
          console.error(error);
          toast.error("Erro ao criar ordem de serviço");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchServiceOrder = async () => {
    try {
      const serviceOrderService = new ServiceOrderService();
      const response = await serviceOrderService.get(
        serviceOrder?.id ?? initialServiceOrder.id
      );
      setServiceOrder(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao carregar alerta");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialServiceOrder) {
      if (initialServiceOrder.id) {
        setIsNewServiceOrder(false);
      }
      setServiceOrder(initialServiceOrder);
      if (loading && initialServiceOrder) {
        fetchServiceOrder();
      }
    } else {
      setIsNewServiceOrder(true);
      const serviceOrderService = new ServiceOrderService();
      setServiceOrder(serviceOrderService.getEmpty());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialServiceOrder]);

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "priority" || name === "status") {
      value = value[0]["id"];
    }
    setServiceOrder({ ...serviceOrder, [name]: value });
  };

  return (
    <>
      <div className={classes.mainColumn}>
        {isNewServiceOrder && (
          <Typography variant="h4" style={{ marginBottom: "1rem" }}>
            Nova ordem de serviço
          </Typography>
        )}

        {/* Asset Name */}
        <div className={classes.row}>
          <FontAwesomeIcon icon={faToolbox} color={"#888"} />
          <EditingTextField
            name={"title"}
            hint={"Título da ordem de serviço"}
            data={serviceOrder && serviceOrder.title ? serviceOrder.title : ""}
            higlighted={true}
            onChange={handleChange}
          />

          <SelectingTextField
            name="Responsável"
            fieldId={"assigned"}
            label={"email"}
            icon={faHelmetSafety}
            data={
              serviceOrder &&
              serviceOrder.assigned &&
              serviceOrder.assigned.length > 0
                ? serviceOrder.assigned[0].email
                : null
            }
            onChange={handleChange}
            highlighted={true}
            options={users ?? []}
          />
        </div>

        <EditingTextField
          name={"description"}
          hint={"Descrição"}
          data={
            serviceOrder && serviceOrder.description
              ? serviceOrder.description
              : ""
          }
          multiline={true}
          minLines={isFromModal ? 10 : 1}
          onChange={handleChange}
          higlighted={true}
        />

        <Divider />

        <div className={classes.row}>
          <SelectingTextField
            name="Prioridade"
            fieldId={"priority"}
            icon={faSquareCaretUp}
            data={
              serviceOrder && serviceOrder.priority
                ? {
                    id: serviceOrder.priority,
                    name: PriorityName[serviceOrder.priority ?? "low"],
                  }
                : null
            }
            onChange={handleChange}
            highlighted={true}
            options={[
              { id: "low", name: "Baixa" },
              { id: "medium", name: "Média" },
              { id: "high", name: "Alta" },
              { id: "urgent", name: "Urgente" },
            ]}
          />

          <DatePickerFormField
            name={"Data"}
            fieldId={"scheduled_at"}
            icon={faCalendarCheck}
            data={
              serviceOrder && serviceOrder.scheduled_at
                ? serviceOrder.scheduled_at
                : null
            }
            onChange={handleChange}
            highlighted={true}
          />
        </div>

        <div className={classes.row}>
          <SelectingTextField
            name="Ativo"
            fieldId={"assets"}
            icon={faBullseye}
            data={
              serviceOrder &&
              serviceOrder.assets &&
              serviceOrder.assets.length > 0
                ? serviceOrder.assets[0].name
                : null
            }
            onChange={handleChange}
            highlighted={true}
            options={assets ?? []}
          />

          <AlertViewTextField
            alert={
              serviceOrder &&
              serviceOrder?.alerts &&
              serviceOrder?.alerts?.length > 0
                ? serviceOrder.alerts[0]
                : null
            }
            isEmpty={serviceOrder && serviceOrder?.alerts?.length === 0}
          />
        </div>

        {serviceOrder && !isNewServiceOrder && (
          <div
            className={classes.row}
            style={{
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            />
            <SelectingTextField
              name="Status"
              fieldId={"status"}
              icon={faSquareCaretUp}
              data={
                serviceOrder && serviceOrder.status
                  ? {
                      id: serviceOrder.status,
                      name: StatusName[serviceOrder.status ?? "todo"],
                    }
                  : null
              }
              onChange={handleChange}
              highlighted={true}
              options={[
                { id: "todo", name: "Aberto" },
                { id: "in-progress", name: "Em progresso" },
                { id: "closed", name: "Fechado" },
              ]}
            />
          </div>
        )}
      </div>
      <div className={classes.savingSection}>
        <Divider />

        <div
          className={classes.row}
          style={{
            gap: "30px",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Button variant="text" color="primary" onClick={handleClose}>
            {"Cancelar"}
          </Button>
          <Button
            color="primary"
            variant="outlined"
            disabled={loading}
            onClick={handleSave}
            startIcon={
              loading ? (
                <CircularProgress size={20} />
              ) : (
                <FontAwesomeIcon
                  icon={isNewServiceOrder ? faPlusCircle : faSave}
                />
              )
            }
          >
            {isNewServiceOrder ? "Criar" : "Salvar"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ServiceOrderBody;
