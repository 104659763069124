import apiClient from "../apiClient";

class AlertService {
  constructor() {
    this.apiClient = apiClient;
  }

  getList({
    page = 1,
    perPage = 10,
    sortField = "id",
    sortOrder = "asc",
    filters = {},
  } = {}) {
    return this.apiClient.get("api/v1/alert/", {
      params: {
        page,
        per_page: perPage,
        sort_field: sortField,
        sort_order: sortOrder,
        filters: JSON.stringify(filters),
      },
      cache: false,
    });
  }

  getReasons() {
    return this.apiClient.get("api/v1/alert-problem/", {
      params: { page: 1, per_page: 100 },
    });
  }

  read(alert_id) {
    return this.apiClient.patch(`api/v1/alert/${alert_id}/`,
      {
        read_status: true,
      },
      {
        cache: false,
      }
    );
  }

  get(alert_id) {
    return this.apiClient.get(`api/v1/alert/${alert_id}/`, { cache: false });
  }

  validateAlert(asset) {
    return null;
  }

  updateField(alert_id, field, value) {
    console.log("updateField", alert_id, field, value);
    return this.apiClient.patch(`api/v1/alert/${alert_id}/`,
      {
        [field]: value,
      },
      {
        cache: false,
      },
    );
  }
}

export default AlertService;
