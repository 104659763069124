import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachographDigital } from "@fortawesome/free-solid-svg-icons";
import ShimmerBox from "../shimmer/ShimmerBox";
import VibrationService from "../../../api/services/vibrations";
import VibrationSpectrogram from "../VibrationSpectrogram/VibrationSpectrogram";
import GenericInformationSection from "../GenericSections/GenericInformationSection";

const useStyles = makeStyles((theme) => {
  return {
    column: {
      gap: "1rem",
      padding: "0",
      overflowY: "auto",
      flexDirection: "column",
    },
    row: {
      gap: "1rem",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    section: {
      gap: "1rem",
      display: "flex",
      padding: "0rem",
      marginTop: "1rem",
      maxHeight: "700px",
      flexDirection: "column",
    },
    sectionContent: {
      width: "100%",
      height: "400px",
      padding: "0.5rem",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.divider}`,
    },
  };
});

const AssetVibrationPatternSection = ({
  asset: initialAsset,
  title = null,
}) => {
  let timeoutId;
  const classes = useStyles();
  const [pattern, setPattern] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchVibrationPattern = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setIsLoading(true);
      let vibrationService = new VibrationService();
      vibrationService
        .getLatest({
          page: 1,
          perPage: 1,
          byAsset: initialAsset.asset_id,
          withOrigin: "asset_pattern",
        })
        .then((response) => {
          if (response.data.results.length > 0) {
            setPattern(response.data.results[0]);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("Erro ao buscar padrões de vibração para o ativo");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 500);
  };

  useEffect(() => {
    fetchVibrationPattern();
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classes.section}>
        <div className={classes.row}>
          <FontAwesomeIcon icon={faTachographDigital} size="x" color="#444" />
          <Typography variant="h6">
            {title ? title : "Padrão de vibração"}
          </Typography>
        </div>
        {isLoading ? (
          <ShimmerBox height="350px" />
        ) : pattern ? (
          <div className={classes.sectionContent}>
            <VibrationSpectrogram vibrationData={pattern?.content} />
          </div>
        ) : (
          <GenericInformationSection
            title={"Nenhum padrão criado"}
            message={
              "Ainda não há padrões de vibração criados para este ativo. " +
              "Os padrões de vibração são criados a partir de 10 medições de vibração para cada ativo. " +
              "Assim que as medições forem realizadas, os padrões de vibração serão criados automaticamente."
            }
          />
        )}
      </div>
    </>
  );
};

export default AssetVibrationPatternSection;
