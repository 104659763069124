import React from "react";
import { styled } from "@mui/system";
import { alpha } from "@mui/material";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  width: "94%",
  margin: "10px",
  borderRadius: "10px",
  transition: "all 0.3s ease",
  "&.Mui-selected": {
    color: "#fff",
    backgroundColor: alpha("#666", 0.9),
  },
  "&&:hover": {
    backgroundColor: alpha("#666", 0.2),
  },
}));

const MenuItem = ({ text, icon, isSelected, onClick }) => {
  return (
    <StyledListItem button selected={isSelected} onClick={onClick}>
      <ListItemIcon>
        {<FontAwesomeIcon icon={icon} color={isSelected ? "#fff" : null} />}
      </ListItemIcon>
      <ListItemText primary={text} />
    </StyledListItem>
  );
};

export default MenuItem;
