import React from "react";
import { Button, Drawer, List, useTheme } from "@mui/material";
import {
  faExclamationCircle,
  faIndustry,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@mui/styles";
import logo from "../../../../assets/eulera_logo_01.svg";
import MenuItem from "./MenuItem";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 300,
    overflow: "auto",
  },
  header: {
    display: "flex",
    width: "300px",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const menuItems = [
  // {
  //   name: "Dashboard",
  //   url: "dashboard/dashboard",
  //   icon: faDashboard,
  // },
  {
    name: "Alertas",
    url: "dashboard/alertas",
    icon: faExclamationCircle,
  },
  {
    name: "Ativos",
    url: "dashboard/ativos",
    icon: faIndustry,
  },

  {
    name: "Usuários",
    url: "dashboard/usuarios",
    icon: faUsers,
  },
  // {
  //   name: "Ordens de Serviço",
  //   url: "dashboard/ordens-de-servico",
  //   icon: faFileContract,
  // },
];

const Sidebar = () => {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();

  const getRoute = (text) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .replace(/ /g, "-");
  };

  const handleSignOut = () => {
    Cookies.remove("authToken");
    // redirect to login or logout the user
    window.location.href = "/login"; // replace with your login route
  };

  return (
    <Drawer variant="permanent" className={classes.drawer}>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <List>
          <div className={classes.header}>
            <img
              src={logo}
              alt="Logo"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          {menuItems.map((item, index) => {
            const route = getRoute(item.name);
            const isSelected = location.pathname === `/${item.url}`;
            return (
              <nav key={index}>
                <Link
                  to={route}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <MenuItem
                    text={item.name}
                    isSelected={isSelected}
                    icon={item.icon}
                  />
                </Link>
              </nav>
            );
          })}
        </List>
        <div style={{ flexGrow: 1 }} />
        <div style={{ padding: theme.spacing(2) }}>
          <Button variant="outlined" fullWidth onClick={handleSignOut}>
            Sair
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
