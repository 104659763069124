import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  Pagination,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCalendar,
  faCircleExclamation,
  faEyeSlash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import ShimmerBox from "../shimmer/ShimmerBox";
import AlertService from "../../../api/services/alerts";
import { alertCriteria } from "../../../constants/AlertMapping";
import GenericInformationSection from "../GenericSections/GenericInformationSection";
import { formatDateTime } from "../../../functions/dataFormat";
import AlertModal from "../alerts/AlertModal";

const useStyles = makeStyles((theme) => {
  return {
    column: {
      gap: "1rem",
      // height: "100%",
      // display: "flex",
      padding: "0",
      flexDirection: "column",
      overflowY: "auto",
    },
    alertsColumn: {
      gap: "1rem",
      height: "100%",
      display: "flex",
      padding: "0",
      flexDirection: "column",
      overflowY: "auto",
    },
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
      alignItems: "center",
    },
    card: {
      gap: "1rem",
      display: "flex",
      flexDirection: "column",
      padding: "1rem",
      borderRadius: "10px",
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.shadows[0],
      maxHeight: "1000px",

      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&:hover::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    "alert-card-": {
      transition: "border 0.3s ease-in-out",
      "&:hover": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    "alert-card-possible-issue": {
      transition: "border 0.3s ease-in-out",
      "&:hover": {
        border: `1px solid #ff9800`,
      },
    },
    "alert-card-maintenance": {
      transition: "border 0.3s ease-in-out",
      "&:hover": {
        border: `1px solid #da1e28`,
      },
    },
    "alert-card-not-a-problem": {
      transition: "border 0.3s ease-in-out",
      "&:hover": {
        border: `1px solid #666`,
      },
    },
  };
});

const AlertDetailCard = ({ alert, ignoreAlert, openAlertView }) => {
  const classes = useStyles();
  const [ignoreInProgress, setIgnoreInProgress] = useState(false);

  return (
    <Button
      style={{
        textTransform: "none",
        justifyContent: "start",
        textAlign: "left",
        color: "inherit",
        padding: "0",
        margin: "0",
        width: "100%",
      }}
      onClick={() => { }}
      disableRipple
    >
      <div
        className={`${classes.card} ${classes["alert-card"]}
        ${classes[`alert-card-${alert.criteria.toLowerCase()}`]}
      `}
      >
        <div className={classes.row}>
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", color: "#444" }}
          >
            {alert.title}
          </Typography>
        </div>
        <Divider />
        <div className={classes.row}>
          <FontAwesomeIcon icon={faCalendar} size="3px" color="#444" />
          <Typography variant="body2" color={"#444"}>
            {formatDateTime(alert.created_at)}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <FontAwesomeIcon icon={faCircleExclamation} size="x" color="#444" />
          <Typography variant="body2" color={"#444"}>
            {alertCriteria[alert.criteria]}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography variant="body2">{alert.description}</Typography>
        </div>
        <Divider />
        <div className={classes.row} style={{ justifyContent: "end" }}>
          <Button
            size="small"
            color="primary"
            variant="text"
            onClick={(event) => {
              setIgnoreInProgress(true);
              event.stopPropagation();
              ignoreAlert(alert)
                .then(() => { })
                .catch((error) => {
                  console.error(error);
                  toast.error("Erro ao ignorar alerta");
                })
                .finally(() => {
                  setIgnoreInProgress(false);
                });
            }}
            disabled={ignoreInProgress || alert.criteria === "not-a-problem"}
            startIcon={
              ignoreInProgress ? (
                <CircularProgress size={12} />
              ) : (
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  style={{ fontSize: "12px" }}
                />
              )
            }
          >
            Ignorar
          </Button>

          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => {
              openAlertView(alert);
              console.log("Opening alert: ", alert);
            }}
            startIcon={
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ fontSize: "12px" }}
              />
            }
          >
            Visualizar
          </Button>
        </div>
      </div>
    </Button>
  );
};

const AlertsSection = ({ asset: initialAsset, onParentClose }) => {
  let timeoutId;
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [alerts, setAlerts] = useState(null);
  const [alert, setAlert] = useState(null);
  const [isAlertViewOpen, setIsAlertViewOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const handleAlertViewClose = () => {
    setIsAlertViewOpen(false);
    setAlert(null);
  };

  const handleAlertViewOpen = (alert) => {
    setAlert(alert);
  };

  const ignoreAlert = async (alert) => {
    let alertService = new AlertService();
    try {
      const response = await alertService.ignore(alert.id);
      let newAlert = response.data;
      let newAlerts = alerts.map((a) => {
        if (a.id === newAlert.id) {
          return newAlert;
        }
        return a;
      });
      setAlerts(newAlerts);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao ignorar alerta");
    }
  };

  const fetchAlertsForAsset = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setIsLoading(true);
      let alertService = new AlertService();
      alertService
        .getList({
          page: page,
          perPage: perPage,
          filters: {
            asset_id: initialAsset.id,
            criteria: "not-a-problem",
          },
        })
        .then((response) => {
          if (response.data.page !== page) setPage(response.data.page);
          if (response.data.per_page !== perPage)
            setPerPage(response.data.per_page);
          if (response.data.total !== total) setTotal(response.data.total);
          setAlerts(response.data.results);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Erro ao buscar alertas para o ativo");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 500);
  };

  useEffect(() => {
    fetchAlertsForAsset();
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  useEffect(() => {
    if (alert) {
      setIsAlertViewOpen(true);
      // onParentClose();
    } else {
      setIsAlertViewOpen(false);
    }
  }, [alert, onParentClose]);

  return (
    <>
      <div>
        <div className={classes.row} style={{ marginBottom: "1rem" }}>
          <FontAwesomeIcon icon={faTriangleExclamation} size="x" color="#444" />
          <Typography variant="h6">Alertas gerados</Typography>
        </div>

        {alerts && alerts.length > 0 && !isLoading ? (
          <div className={classes.alertsColumn}>
            {alerts.map((alert, index) => (
              <AlertDetailCard
                key={index}
                alert={alert}
                ignoreAlert={ignoreAlert}
                openAlertView={handleAlertViewOpen}
              />
            ))}
          </div>
        ) : alerts && alerts.length === 0 && !isLoading ? (
          <GenericInformationSection
            icon={faTriangleExclamation}
            title={"Nenhum alerta gerado"}
            message={
              "Nenhum alerta foi gerado para este ativo. " +
              "Isso pode ser um bom sinal, mas fique atento a possíveis problemas."
            }
          />
        ) : (
          <div className={classes.alertsColumn}>
            <ShimmerBox height={"50px"} width="100%" />
            <ShimmerBox height={"50px"} width="100%" />
            <ShimmerBox height={"50px"} width="100%" />
            <ShimmerBox height={"50px"} width="100%" />
            <ShimmerBox height={"50px"} width="100%" />
          </div>
        )}

        <div
          className={classes.row}
          style={{
            gap: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Pagination
            page={page}
            count={Math.ceil(total / perPage)}
            onChange={(event, value) => setPage(value)}
            color="primary"
          />
        </div>
        <AlertModal
          alert={alert}
          isOpen={isAlertViewOpen}
          onClose={handleAlertViewClose}
        />
      </div>
    </>
  );
};

export default AlertsSection;
