import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faBullseye,
  faCalendar,
  faExclamationTriangle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import AlertService from "../../../api/services/alerts";
import IgnoreAlertButton from "./IgnoreAlertButton";
import ViewTextField from "../form/ViewTextField";
import DeviceViewTextField from "../form/DeviceViewTextField";
import ServiceOrdersSection from "./ServiceOrdersSection";
import ServiceOrderModal from "../serviceOrders/ServiceOrderModal";
import ServiceOrderService from "../../../api/services/serviceOrders";
import ResolutionSection from "./ResolutionSection";
import AlertVibrationPatternSection from "./AlertVibrationPatternSection";
import AssetVibrationPatternSection from "../assets/AssetVibrationPatternSection";
import { formatDateTime } from "../../../functions/dataFormat";

const useStyles = makeStyles((theme) => ({
  content: {
    gap: "1rem",
    width: "100%",
    height: "100%",
    padding: "2rem",
    flexDirection: "column",
    paddingBottom: "0rem",
  },
  mainColumn: {
    gap: "1rem",
    width: "100%",
    // height: "90%",
    display: "flex",
    padding: "2rem",
    overflowY: "auto",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
  },
  mainRow: {
    gap: "1rem",
    display: "flex",
    height: "100%",
    alignItems: "start",
    flexDirection: "row",
  },
  rowEnding: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
  },
  evidenceImage: {
    padding: "1rem",
    borderRadius: "5px",
    border: "1px solid #ddd",
  },

  minimalWidthRow: {
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem",
    alignItems: "center",
  },
  smallCircle: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    backgroundColor: "#ddd",
  },
  savingSection: {
    gap: "10px",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    padding: "1rem",
  },
  textField: {
    width: "100%",
    padding: "1rem",
    borderRadius: "5px",
    backgroundColor: "#f5f5f5",
  },
}));

const AlertBody = ({
  alert: initialAlert,
  isOpen,
  onClose,
  onSubmitCallback,
}) => {
  const classes = useStyles();
  const [alert, setAlert] = useState(initialAlert);
  const [loading, setLoading] = useState(true);
  const [initialServiceOrder, setInitialServiceOrder] = useState(null);
  const [isServiceOrderViewOpen, setIsServiceOrderViewOpen] = useState(false);

  const handleServiceOrderViewClose = () => {
    setIsServiceOrderViewOpen(false);
    fetchAlert();
  };

  const handleClose = () => {
    onClose();
    setAlert(null);
    setLoading(true);
  };

  const createServiceOrder = async () => {
    const serviceOrderService = new ServiceOrderService();
    let newServiceOrder = serviceOrderService.getEmpty();
    newServiceOrder.alerts = [alert];
    setInitialServiceOrder(newServiceOrder);
    setIsServiceOrderViewOpen(true);
  };

  const fetchAlert = async () => {
    try {
      const alertService = new AlertService();
      const response = await alertService.get(alert?.alert_id ?? initialAlert.alert_id);
      setAlert(response.data);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao carregar alerta");
    } finally {
      setLoading(false);
    }
  };

  const markAlertAsRead = async (alertReference) => {
    if (
      alertReference &&
      alertReference?.alert_id &&
      alertReference?.read_status === false
    ) {
      try {
        const alertService = new AlertService();
        await alertService.read(alertReference.alert_id);
      } catch (error) {
        console.log(error);
        toast.error("Error marking alert as read");
      }
    }
  };

  useEffect(() => {
    setAlert(initialAlert);
    markAlertAsRead(alert ?? initialAlert);
    if (loading && initialAlert) {
      fetchAlert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAlert]);

  return (
    <>
      <div className={classes.content}>
        <div className={classes.mainRow}>
          <div className={classes.mainColumn}>
            <div className={classes.row}>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="2x"
                color="#444"
              />
              <Typography variant="h4">{alert?.title}</Typography>
            </div>

            <div className={classes.row}>
              <div className={classes.smallCircle} />
              <FontAwesomeIcon icon={faCalendar} size="sm" color="#444" />
              <Typography variant="body1">{formatDateTime(alert?.created_at)}</Typography>
              <div className={classes.smallCircle} />
            </div>

            <Divider />

            <Typography variant="body1" className={classes.textField}>
              {alert?.description}
            </Typography>

            <ViewTextField
              icon={faBullseye}
              name={"Máquina"}
              data={alert?.asset?.name}
            />

            <DeviceViewTextField device={alert?.asset?.asset_id} />

            <Divider />

            <ResolutionSection alert={alert} callback={fetchAlert} />

            <div style={{ height: "1rem", marginBottom: "2rem" }} />

            <Typography variant="h6">{"Ordens de Serviço"}</Typography>
            <Divider />

            <ServiceOrdersSection alert={alert} />

            <div style={{ height: "2rem" }} />
          </div>

          <div className={classes.mainColumn}>
            <AlertVibrationPatternSection alert={alert} />
            <AssetVibrationPatternSection
              asset={alert?.asset}
              title={"Padrão de vibração do ativo"}
            />
          </div>
        </div>

        <div className={classes.savingSection}>
          <Divider />

          <div
            className={classes.row}
            style={{
              gap: "30px",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              color="primary"
              startIcon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
              onClick={handleClose}
            >
              {"Voltar"}
            </Button>

            <IgnoreAlertButton alert={alert} size="large" />

            <Button
              color="primary"
              variant="outlined"
              disabled={loading}
              onClick={createServiceOrder}
              startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
            >
              {"Criar ordem de serviço"}
            </Button>
          </div>
        </div>
      </div>
      <ServiceOrderModal
        serviceOrder={initialServiceOrder}
        isOpen={isServiceOrderViewOpen}
        onClose={handleServiceOrderViewClose}
      />
    </>
  );
};

export default AlertBody;
