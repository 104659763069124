import React from "react";
import { makeStyles } from "@mui/styles";
import { Route, Routes } from "react-router-dom";
// import DashboardPage from "../DashboardPage/DashboardPage";
import Sidebar from "./components/SideBar";
import UsersPage from "../UsersPage/UsersPage";
import AssetsPage from "../AssetsPage/AssetsPage";
import AlertsPage from "../AlertsPage/AlertsPage";
import TutorialDashboard from "../../global/TutorialDashboard/TutorialDashboard";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "300px !important",
    overflow: "auto",
  },
  header: {
    display: "flex",
    width: "300px",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    flexGrow: 1,
    padding: "1rem",
    // marginLeft: 250,
  },
}));

const DashboardLayout = () => {
  const classes = useStyles();

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <main className={classes.content}>
        <TutorialDashboard />
        <Routes>
          <Route path="/" element={<AlertsPage />} />
          {/* <Route path="dashboard" element={<DashboardPage />} /> */}
          <Route path="ativos" element={<AssetsPage />} />
          <Route path="alertas" element={<AlertsPage />} />
          <Route path="usuarios" element={<UsersPage />} />
        </Routes>
      </main>
    </div>
  );
};

export default DashboardLayout;
