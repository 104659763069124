import React from "react";
import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EuleroTooltip from "../../Tooltip";

const useStyles = makeStyles((theme) => ({
  tag: {
    gap: "5px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    padding: "5px 10px",
    borderRadius: "5px",
    color: "white",
    fontWeight: "bold",
    fontSize: "0.75rem",
    textTransform: "capitalize",
    maxWidth: "130px",
  },
  smallCircle: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
  },
}));

const AssetStateTag = ({ asset }) => {
  let stateTag;
  let stateColor;
  let stateMessage;
  const classes = useStyles();

  if (asset.colletion_status === "setup") {
    stateColor = "#0062f5";
    stateTag = "Aprendendo";
    stateMessage = "Estamos aprendendo o comportamento do seu ativo";
  } else if (asset.colletion_status === "stopped") {
    stateColor = "#f44336";
    stateTag = "Desativado";
    stateMessage = "Seu asset está desativado";
  } else if (asset.colletion_status === "paused") {
    stateColor = "#ffeb3b";
    stateTag = "Pausado";
    stateMessage = "Seu asset está pausado";
  } else if (asset.colletion_status === "configuring") {
  } else {
    stateColor = "#4caf50";
    stateTag = "Monitoramento";
    stateMessage = "Seu asset está online";
  }

  return (
    <EuleroTooltip title={stateMessage}>
      <div
        className={classes.tag}
        style={{
          color: stateColor,
          backgroundColor: alpha(stateColor, 0.3),
        }}
      >
        <div
          className={classes.smallCircle}
          style={{ backgroundColor: stateColor }}
        />
        {stateTag}
      </div>
    </EuleroTooltip>
  );
};

export default AssetStateTag;
