import React from "react";
import { makeStyles } from "@mui/styles";
import EuleroTooltip from "./Tooltip";
import { alpha } from "@mui/material";
import { DeviceStatusColor } from "../../theme/StatusColor";

const useStyles = makeStyles((theme) => ({
  statusTag: {
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "3px",
    paddingBottom: "3px",
    fontSize: "0.75rem",
  },
  statusCircle: {
    height: "7px",
    width: "7px",
    borderRadius: "100%",
    marginRight: "5px",
  },
}));

const statusTranslations = {
  setup: "Configuração",
  online: "Online",
  offline: "Offline",
  "no-response": "Sem resposta",
};

const DeviceStatusTag = ({ device }) => {
  const classes = useStyles();

  return (
    <EuleroTooltip title={statusTranslations[device.status]}>
      <div
        className={classes.statusTag}
        style={{
          cursor: "pointer",
          backgroundColor: alpha(DeviceStatusColor[device.status], 0.3),
        }}
      >
        <div
          className={classes.statusCircle}
          style={{
            color: DeviceStatusColor[device.status],
            backgroundColor: DeviceStatusColor[device.status],
          }}
        />
        <span>{statusTranslations[device.status]}</span>
      </div>
    </EuleroTooltip>
  );
};

export default DeviceStatusTag;
