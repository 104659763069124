import React from "react";
import { alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EuleroTooltip from "../../Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  tag: {
    gap: "5px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    padding: "5px 10px",
    borderRadius: "5px",
    color: "white",
    fontWeight: "bold",
    fontSize: "0.75rem",
    textTransform: "capitalize",
    maxWidth: "120px",
  },
  smallCircle: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
  },
}));

const AssetHealthTag = ({ asset }) => {
  let stateTag;
  let stateColor;
  let stateMessage;
  let hasAlerts = false;
  const classes = useStyles();

  if (
    asset === null ||
    asset.alerts === null ||
    !asset.alerts ||
    asset.alerts.length === 0
  ) {
    stateColor = "#4caf50";
    stateTag = "Sem alertas";
    stateMessage = "Nenhum alerta detectado para este ativo";
  } else {
    hasAlerts = true;
    stateColor = "#f44336";
    stateTag = `${asset.alerts.length} Alertas`;
    stateMessage = "Este ativo possui alertas pendentes";
  }

  return (
    <EuleroTooltip title={stateMessage}>
      <div
        className={classes.tag}
        style={{
          color: stateColor,
          backgroundColor: alpha(stateColor, 0.3),
        }}
      >
        <FontAwesomeIcon
          icon={hasAlerts ? faExclamationTriangle : faShieldAlt}
          color={stateColor}
        />

        {stateTag}
      </div>
    </EuleroTooltip>
  );
};

export default AssetHealthTag;
