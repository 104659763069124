import React from "react";
import { Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AlertBody from "./AlertBody";

const useStyles = makeStyles((theme) => ({
  modalCard: {
    display: "flex",
    maxWidth: "90%",
    maxHeight: "80%",
    overflowY: "auto",
    borderRadius: "10px",
    flexDirection: "column",
    boxShadow: theme.shadows[5],
    transform: "translate(7%, 10%)",
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
  },
  modalBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
}));

const AlertModal = ({
  alert: initialAlert,
  isOpen,
  onClose,
  onSubmitCallback,
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      BackdropProps={{ className: classes.modalBackdrop }}
    >
      <div className={classes.modalCard}>
        <AlertBody
          alert={initialAlert}
          isOpen={isOpen}
          onClose={onClose}
          onSubmitCallback={onSubmitCallback}
        />
      </div>
    </Modal>
  );
};

export default AlertModal;
