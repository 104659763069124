import apiClient from "../apiClient";
import UserService from "./users";

class AssetService {
  constructor() {
    this.apiClient = apiClient;
  }

  getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.authToken}`,
    };
  }

  async getList({
    page = 1,
    perPage = 10,
    sortField = "id",
    sortOrder = "asc",
    filters = null,
  } = {}) {
    const userService = new UserService();
    const response = await userService.getMe();
    const employee = response.data;
    const company_id = employee.company.company_id;
    return this.apiClient.get("api/v1/asset/", {
      params: {
        page,
        per_page: perPage,
        sort_field: sortField,
        sort_order: sortOrder,
        company: company_id,
        filters: filters ?? {},
      },
      cache: false,
    });
  }

  getModels() {
    return this.apiClient.get("api/v1/asset-model/", {
      cache: false,
    });
  }

  getBrands() {
    return this.apiClient.get("api/v1/asset-brand/", {
      cache: false,
    });
  }

  get(id) {
    return this.apiClient.get(`api/v1/asset/${id}`, {
      cache: false,
    });
  }

  getEmpty() {
    return {
      name: null,
      nickname: null,
      location: null,
      year: null,
      serial_number: null,
      description: null,
      notes: null,
      brand: null,
      model: null,
      device: null,
      company: null,
    };
  }

  validateAsset(asset) {
    if (!asset.name) {
      return { field: "name", message: "Nome é obrigatório" };
    }
    if (!asset.description) {
      return { field: "description", message: "Descrição é obrigatória" };
    }

    if (!asset.nickname) {
      return { field: "nickname", message: "Código é obrigatório" };
    }
    if (!asset.location) {
      return { field: "location", message: "Localização é obrigatório" };
    }
    if (!asset.year) {
      return { field: "year", message: "Ano é obrigatório" };
    }
    if (!asset.serial_number) {
      return {
        field: "serial_number",
        message: "Número de série é obrigatório",
      };
    }
    if (!asset.brand) {
      return { field: "brand", message: "Marca é obrigatória" };
    }
    if (!asset.model) {
      return { field: "model", message: "Modelo é obrigatório" };
    }
    return null;
  }

  async create(asset) {
    // Enhance the asset object with the company ID
    const userService = new UserService();
    const response = await userService.getMe();
    const employee = response.data;
    asset.company = employee.company.company_id;

    // Send the request to the server to create the asset
    return this.apiClient.post("api/v1/asset/", asset, {
      cache: false,
    });
  }

  update(id, asset) {
    return this.apiClient.patch(`api/v1/asset/${id}/`, asset, {
      cache: false,
    });
  }

  updateField(id, field, value) {
    return this.apiClient.patch(
      `api/v1/asset/${id}`,
      {
        [field]: value,
      },
      {
        cache: false,
      }
    );
  }

  delete(id) {
    return this.apiClient.delete(`api/v1/asset/${id}`, {
      cache: false,
    });
  }
}

export default AssetService;
