import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EuleroTooltip from "../Tooltip";
import DeviceService from "../../../api/services/devices";
import { toast } from "react-toastify";
import DeviceStatusTag from "../DeviceStatusTag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignalPerfect } from "@fortawesome/free-solid-svg-icons";
import { DeviceStatusColor } from "../../../theme/StatusColor";
import { formatDateTime } from "../../../functions/dataFormat";

const useStyles = makeStyles((theme) => {
  return {
    column: {
      gap: "1rem",
      height: "100%",
      display: "flex",
      padding: "3rem",
      flexDirection: "column",
    },
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
      alignItems: "center",
    },
    card: {
      gap: "1rem",
      display: "flex",
      flexDirection: "column",
      padding: "1rem",
      borderRadius: "10px",
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.shadows[0],
    },
    online: {
      transition: "border 0.3s ease-in-out",
      "&:hover": {
        border: `1px solid ${DeviceStatusColor["online"]}`,
      },
    },
    offline: {
      transition: "border 0.3s ease-in-out",
      "&:hover": {
        border: `1px solid ${DeviceStatusColor["offline"]}`,
      },
    },
    setup: {
      transition: "border 0.3s ease-in-out",
      "&:hover": {
        border: `1px solid ${DeviceStatusColor["setup"]}`,
      },
    },
    "no-response": {
      transition: "border 0.3s ease-in-out",
      "&:hover": {
        border: `1px solid ${DeviceStatusColor["no-response"]}`,
      },
    },
  };
});

const DeviceConnectionCard = ({ asset: initialAsset, callback }) => {
  let timeoutId;
  let deviceService = new DeviceService();
  const classes = useStyles();
  const [devices, setDevices] = useState([]);
  const [currentDevice, setCurrentDevice] = useState(null);

  const fetchAssetDevice = () => {
    console.log(initialAsset);
    if (initialAsset && initialAsset.asset_id) {
      deviceService
        .getList({ assetId: initialAsset.asset_id })
        .then((response) => {
          if (response.data.results.length > 0) {
            setCurrentDevice(response.data.results[0]);
          }
        })
        .catch((error) => {
          toast.error("Erro ao buscar dispositivo do ativo.");
          console.error(error);
        });
    }
  };

  const fetchAvailableDevices = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      deviceService
        .getAvailableDevices()
        .then((response) => {
          if (!response.data["results"]) {
            return;
          }
          let newDevicesList = response.data["results"];
          setDevices(newDevicesList);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Erro ao buscar dispositivos disponíveis.");
        });
    }, 1000);
  };

  useEffect(() => {
    fetchAvailableDevices();
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => fetchAssetDevice(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialAsset]
  );

  return (
    <div>
      <div
        className={`${classes.card} ${
          classes[currentDevice?.status || "offline"]
        }`}
      >
        <div className={classes.row}>
          <FontAwesomeIcon icon={faSignalPerfect} size="1x" color="#444" />
          <Typography variant="h6">Conexão do dispositivo</Typography>
        </div>
        {!currentDevice && (
          <p>Selecione um dispositivo para conectar ao ativo.</p>
        )}
        {devices.length === 0 && !currentDevice ? (
          <EuleroTooltip title={"Nenhum dispositivo disponível"}>
            <Autocomplete
              options={[]}
              disabled={true}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size={"small"} />
              )}
            />
          </EuleroTooltip>
        ) : (
          <Autocomplete
            id={"device"}
            options={devices.concat(currentDevice || [])}
            className={classes.textField}
            getOptionLabel={(option) => `Dispositivo ${option.identifier}`}
            value={currentDevice}
            onChange={(event, newValue) => {
              if (newValue === null) {
                deviceService
                  .detachFromAsset(currentDevice.device_id)
                  .then(() => setCurrentDevice(null))
                  .finally(() => fetchAvailableDevices());
              } else {
                deviceService
                  .attachToAsset(newValue.device_id, initialAsset.asset_id)
                  .then((response) => setCurrentDevice(response.data))
                  .finally(() => fetchAvailableDevices());
              }
              callback({ target: { name: "device", value: newValue } });
            }}
            isOptionEqualToValue={(option, value) =>
              option.device_id === value.device_id
            }
            noOptionsText={"Nenhum dispositivo disponível"}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" size={"small"} />
            )}
          />
        )}
        {currentDevice && (
          <>
            <div className={classes.row}>
              <Typography variant="body1">
                <strong>Última atualização:</strong>
              </Typography>
              <Typography variant="body1">
                {currentDevice.last_updated_at
                  ? formatDateTime(currentDevice.last_updated_at)
                  : "N/A"}
              </Typography>
            </div>
            <div className={classes.row}>
              <Typography variant="body1">
                <strong>Status:</strong>
              </Typography>
              <DeviceStatusTag device={currentDevice} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DeviceConnectionCard;
