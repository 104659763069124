import { Typography, alpha } from "@mui/material";
import { AlertCriteriaColor } from "../../../theme/StatusColor";
import { makeStyles } from "@mui/styles";
import { TaxCriteriaName } from "../../../theme/Taxonomy";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    gap: theme.spacing(2),
  },
  smallCircle: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
  },
  tag: {
    whiteSpace: "nowrap",
    width: "min-content",
    paddingTop: "0.3rem",
    paddingBottom: "0.3rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    borderRadius: "5px",
    backgroundColor: "transparent",
    border: `1px solid transparent`,
    transition: "all 0.1s ease-in-out",
  },
  "not-a-problem": {
    color: AlertCriteriaColor["not-a-problem"],
    backgroundColor: alpha(AlertCriteriaColor["not-a-problem"], 0.3),
    "&:hover": {
      border: `1px solid ${AlertCriteriaColor["not-a-problem"]}`,
    },
  },
  maintenance: {
    color: AlertCriteriaColor["maintenance"],
    backgroundColor: alpha(AlertCriteriaColor["maintenance"], 0.3),
    "&:hover": {
      border: `1px solid ${AlertCriteriaColor["maintenance"]}`,
    },
  },
  "possible-issue": {
    color: AlertCriteriaColor["possible-issue"],
    backgroundColor: alpha(AlertCriteriaColor["possible-issue"], 0.3),
    "&:hover": {
      border: `1px solid ${AlertCriteriaColor["possible-issue"]}`,
    },
  },
}));

const AlertCriteriaTag = ({ criteria, size = "small" }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.tag} ${classes[criteria ?? "not-a-problem"]}`}>
      <div className={classes.row}>
        <div
          className={classes.smallCircle}
          style={{
            backgroundColor: AlertCriteriaColor[criteria ?? "not-a-problem"],
          }}
        />
        <Typography variant={"body1" || size === "small" ? "body2" : "body1"}>
          {TaxCriteriaName[criteria ?? "not-a-problem"]}
        </Typography>
      </div>
    </div>
  );
};

export default AlertCriteriaTag;
