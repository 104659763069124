import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect, useCallback } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import DeviceService from "../../../../api/services/devices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  column: {
    gap: "1rem",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rowEnd: {
    gap: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
  },
}));

const TutorialStepThree = ({ device: initialDevice, callback }) => {
  const classes = useStyles();
  const [isWaiting, setWaiting] = useState(true);
  const [device, setDevice] = useState(initialDevice);

  useEffect(() => setDevice(initialDevice), [initialDevice]);

  const fetchDevice = useCallback(() => {
    if (!device || !device?.device_id) {
      return;
    }

    try {
      let deviceService = new DeviceService();
      deviceService
        .get(device.device_id)
        .then((response) => {
          setDevice(response.data);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Erro ao atualizar ativo");
        });
    } catch (error) {
      console.log(error);
      toast.error("Erro ao atualizar ativo");
    }
  }, [device]);

  useEffect(() => {
    let intervalId;
    if (isWaiting) intervalId = setInterval(fetchDevice, 10000);
    return () => clearInterval(intervalId);
  }, [isWaiting, fetchDevice]);

  useEffect(() => {
    if (
      (device?.status === "setup" || device?.status === "online") &&
      device?.last_updated_at
    ) {
      setWaiting(false);
    }
  }, [device]);

  const handleClick = () => {
    callback({ step: "4 step" });
  };

  return (
    <div className={classes.column}>
      <div className={classes.column}>
        <div className={classes.row}>
          {isWaiting && (
            <>
              <CircularProgress size={12} />
              <Typography variant="h5">Esperando coleta...</Typography>
            </>
          )}

          {!isWaiting && (
            <>
              <Typography variant="h5">
                Coleta realizada com sucesso!
              </Typography>
            </>
          )}
        </div>
        <Typography variant="body1">
          Agora que o dispositivo está conectado, aguarde a primeira coleta de
          dados para continuar. Assim que a coleta for realizada, clique em
          continuar para prosseguir. E caso tenha algum problema, entre em
          contato com o suporte da Eulera.
        </Typography>
      </div>
      <div className={classes.rowEnd}>
        <Button
          variant="outlined"
          disabled={isWaiting}
          onClick={handleClick}
          color={!isWaiting ? "success" : "primary"}
          endIcon={<FontAwesomeIcon icon={faArrowCircleRight} />}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};

export default TutorialStepThree;
