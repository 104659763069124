import React, { useEffect, useState } from "react";
import { Avatar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ServiceOrderService from "../../../api/services/serviceOrders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import EuleroTooltip from "../Tooltip";
import ServiceOrderStatusTag from "../serviceOrders/components/ServiceOrderStatusTag";
import ServiceOrderPriorityTag from "../serviceOrders/components/ServiceOrderPriorityTag";
import ServiceOrderModal from "../serviceOrders/ServiceOrderModal";

const useStyles = makeStyles((theme) => {
  return {
    column: {
      gap: "1rem",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
      alignItems: "center",
    },
    minRow: {
      gap: "0.5rem",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    smallCircle: {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#ddd",
    },
    card: {
      gap: "1rem",
      display: "flex",
      padding: "1rem",
      borderRadius: "10px",
      flexDirection: "column",
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#f5f5f5",
      },
    },
    textField: {
      width: "100%",
      padding: "1rem",
      borderRadius: "5px",
      backgroundColor: "#f5f5f5",
      justifyContent: "center",
      textAlign: "center",
    },
    description: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "100%",
    },
  };
});

const ServiceOrdersSection = ({ alert, refetch = true }) => {
  let timeoutId;
  const classes = useStyles();
  const [serviceOrders, setServiceOrders] = useState(
    alert?.service_orders ?? null
  );
  const [currentServiceOrder, setCurrentServiceOrder] = useState(null);
  const [isServiceOrderViewOpen, setIsServiceOrderViewOpen] = useState(false);

  const fetchServiceOrders = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      const serviceOrderService = new ServiceOrderService();
      if (alert && (alert?.service_orders === null || refetch)) {
        serviceOrderService
          .getList({
            filters: {
              alert_id: alert?.id,
            },
          })
          .then((response) => {
            setServiceOrders(response.data?.results ?? []);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }, 1000);
  };

  const handleServiceOrderViewClose = () => {
    setIsServiceOrderViewOpen(false);
    setCurrentServiceOrder(null);
    fetchServiceOrders();
  };

  useEffect(() => {
    fetchServiceOrders();
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentServiceOrder) {
      setIsServiceOrderViewOpen(true);
    } else {
      setIsServiceOrderViewOpen(false);
    }
  }, [currentServiceOrder]);

  return (
    <>
      <div className={classes.column}>
        {serviceOrders && serviceOrders?.length > 0 ? (
          serviceOrders.map((serviceOrder, index) => {
            return (
              <div
                key={index}
                className={classes.card}
                onClick={() => setCurrentServiceOrder(serviceOrder)}
              >
                <Typography variant="h6">{serviceOrder.title}</Typography>

                <Typography variant="body2" className={classes.description}>
                  {serviceOrder.description}
                </Typography>

                <div className={classes.row}>
                  <FontAwesomeIcon
                    icon={faCalendar}
                    color={"#999"}
                    fontSize={"15px"}
                  />
                  <Typography variant="body1">
                    {`Agendado: ${serviceOrder.scheduled_at}`}
                  </Typography>
                </div>
                <div className={classes.row}>
                  <div
                    className={classes.row}
                    style={{ alignContent: "start" }}
                  >
                    <div className={classes.smallCircle} />

                    <EuleroTooltip
                      title={`${serviceOrder?.assigned[0]?.email}`}
                    >
                      <div className={classes.minRow}>
                        <Avatar
                          alt={serviceOrder?.assigned[0]?.name}
                          src={serviceOrder?.assigned[0]?.avatar}
                          style={{ width: "15px", height: "15px" }}
                        />
                        <Typography variant="body2">
                          {serviceOrder?.assigned[0]?.name ?? ""}
                        </Typography>
                      </div>
                    </EuleroTooltip>

                    <div className={classes.smallCircle} />
                  </div>
                  <div
                    className={classes.row}
                    style={{
                      alignContent: "end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <ServiceOrderPriorityTag serviceOrder={serviceOrder} />
                    <ServiceOrderStatusTag serviceOrder={serviceOrder} />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <Typography variant="body2" className={classes.textField}>
            {
              "Nenhuma ordem de serviço associada a este alerta, crie uma nova ordem de serviço para este alerta."
            }
          </Typography>
        )}
      </div>
      <ServiceOrderModal
        serviceOrder={currentServiceOrder}
        isOpen={isServiceOrderViewOpen}
        onClose={handleServiceOrderViewClose}
      />
    </>
  );
};

export default ServiceOrdersSection;
