export const ServiceOrderPriorityColor = {
  low: "#24A148",
  medium: "#0043ce",
  high: "#da1e28",
  urgent: "#da1e28",
};

export const ServiceOrderStatusColor = {
  todo: "#777",
  "in-progress": "#4994ec",
  done: "#4CAF50",
};

export const DeviceStatusColor = {
  online: "#24A148", // green
  offline: "#6f6f60", // red
  setup: "#0043ce", // yellow
  "no-response": "#da1e28", // gray
};

export const AlertCriteriaColor = {
  "not-a-problem": "#24A148", // green
  maintenance: "#0043ce", // yellow
  "possible-issue": "#da1e28", // red
};
