import apiClient from "../apiClient";

class ServiceOrderService {
  constructor() {
    this.apiClient = apiClient;
  }

  getList({
    page = 1,
    perPage = 10,
    sortField = "id",
    sortOrder = "asc",
    filters = null,
  } = {}) {
    return this.apiClient.get("api/v1/service-orders/", {
      params: {
        page,
        per_page: perPage,
        sort_field: sortField,
        sort_order: sortOrder,
        filters: JSON.stringify(filters),
      },
    });
  }

  get(id) {
    return this.apiClient.get(`api/v1/service-orders/${id}`);
  }

  getEmpty() {
    return {
      id: null,
      nid: null,
      title: null,
      status: "open",
      priority: null,
      description: null,
      attachments: [],
      scheduled_at: null,
      created: null,
      last_modified: null,
      created_by: null,
      last_modified_by: null,
      assigned: [],
      alerts: [],
      assets: [],
    };
  }

  validateServiceOrder(serviceOrder) {
    if (!serviceOrder.title) {
      return { field: "title", message: "Título é obrigatório" };
    }
    if (!serviceOrder.description) {
      return { field: "description", message: "Descrição é obrigatória" };
    }

    if (!serviceOrder.status) {
      return { field: "status", message: "Status é obrigatório" };
    }

    if (!serviceOrder.priority) {
      return { field: "priority", message: "Prioridade é obrigatório" };
    }

    if (!serviceOrder.assets || serviceOrder.assets.length === 0) {
      return { field: "assets", message: "Ativos é obrigatório" };
    }

    return null;
  }

  create(serviceOrder) {
    return this.apiClient.post("api/v1/service-orders/", serviceOrder);
  }

  update(id, serviceOrder) {
    return this.apiClient.put(`api/v1/service-orders/${id}`, serviceOrder);
  }

  delete(id) {}
}

export default ServiceOrderService;
