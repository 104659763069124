import React from "react";

import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    width: "100%",
  },
  label: {
    gap: "10px",
    height: "100%",
    width: "120px",
    minWidth: "120px",
    maxWidth: "120px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "0.8rem",
    backgroundColor: "transparent",
    borderRadius: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  textField: {
    width: "100%",
    height: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      "&:hover": {
        backgroundColor: "#eee", // Cor de fundo quando o mouse passa sobre o TextField
      },
      "&.Mui-focused": {
        borderColor: "transparent", // Cor da borda quando o mouse passa sobre o TextField
        backgroundColor: "#eee", // Cor de fundo quando o TextField está sendo editado
        "& fieldset": {
          borderWidth: 0, // Remove a borda
          notched: false, // Remove o entalhe
        },
      },
      "&.Mui-disabled": {
        backgroundColor: "#ddd", // Cor de fundo quando o TextField está desabilitado
        borderWidth: 0, // Remove a borda
        notched: false, // Remove o entalhe
      },
      "& fieldset": {
        borderWidth: 0, // Remove a borda
        notched: false, // Remove o entalhe
      },
    },
  },
}));

const FormEditingTextField = ({
  data,
  size,
  onChange,
  name = null,
  fieldId = null,
  icon = null,
  hint = null,
  showError = false,
  errorMessage = null,
  forceDisable = false,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(data);

  return (
    <div className={classes.row}>
      <div className={classes.label}>
        {icon && <FontAwesomeIcon icon={icon} color={"#888"} />}
        <label>{name}</label>
      </div>
      <TextField
        id={fieldId}
        name={fieldId}
        size={size ?? "small"}
        variant="outlined"
        className={classes.textField}
        defaultValue={value}
        placeholder={hint}
        error={showError && errorMessage !== null && !value}
        required={errorMessage !== null}
        helperText={showError && !value ? errorMessage : null}
        disabled={forceDisable}
        onChange={(event) => {
          setValue(event.target.value);
          onChange(event);
        }}
        InputProps={{
          disableUnderline: true,
        }}
      />
    </div>
  );
};

export default FormEditingTextField;
