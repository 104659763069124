import { Badge, Button, TableCell, TableRow, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AlertCriteriaTag from "../../alerts/AlertCriteriaTag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { formatDateTime } from "../../../../functions/dataFormat";

const useStyles = makeStyles((theme) => ({
  column: {
    gap: "1rem",
    height: "100%",
    display: "flex",
    padding: "3rem",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
  },
  assetTableCard: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    borderRadius: "8px",
    padding: "20px",
    marginTop: "20px",
    boxShadow: theme.shadows[0],
    backgroundColor: theme.palette.background.paper,
  },
  headerTableCell: {
    fontWeight: "bold",
    gap: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  inCellContentRow: {
    display: "flex",
    gap: "10px",
    justifyContent: "start",
    alignItems: "center",
    alignContent: "start",
  },
  inCellContentRowEnd: {
    display: "flex",
    gap: "10px",
    justifyContent: "end",
    alignItems: "center",
    alignContent: "end",
  },
  hoverRow: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f5f5f5",
    },
  },
}));

const AlertsTableRow = ({ alert, callback }) => {
  const classes = useStyles();

  return (
    <TableRow
      key={alert?.alert_id}
      className={classes.hoverRow}
      onClick={() => {
        callback?.call();
      }}
    >
      <TableCell>
        <Typography variant="body1" color={"#666"}>
          {alert?.title}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2" color={"#666"}>
          {alert?.read_status === false ? (
            <Badge
              color="secondary"
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {formatDateTime(alert?.created_at)}
            </Badge>
          ) : (
            <Typography variant="body2" color={"#666"}>
              {formatDateTime(alert?.created_at)}
            </Typography>
          )}
        </Typography>
      </TableCell>

      <TableCell>
        <AlertCriteriaTag criteria={alert?.criteria} />
      </TableCell>

      <TableCell>
        <Typography variant="body2" color={"#666"}>
          {alert?.asset?.name}
        </Typography>
      </TableCell>

      <TableCell align="right">
        <Button
          size="small"
          color="primary"
          variant="outlined"
          endIcon={<FontAwesomeIcon icon={faArrowCircleRight} />}
          onClick={() => {
            callback?.call();
          }}
        >
          Visualizar
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default AlertsTableRow;
