import apiClient from "../apiClient";

class VibrationService {
  constructor() {
    this.apiClient = apiClient;
  }

  getAsset({ airtableAssetId }) {
    const params = { asset_airtable_id: airtableAssetId };
    return this.apiClient.get(`api/v1/asset/`, {
      params: params,
      cache: false,
    });
  }

  async getLatest({
    page = 1,
    perPage = 1,
    byAsset = null,
    withOrigin = null,
  } = {}) {
    let params = { page, per_page: perPage };

    if (byAsset) {
      params.asset = byAsset;
    }
    if (withOrigin) {
      params.origin = withOrigin;
    }

    return this.apiClient.get("api/v1/vibration/", {
      params: params,
      cache: false,
    });
  }

  getList({
    page = 1,
    perPage = 10,
    byAsset = null,
    byAlert = null,
    byDevice = null,
    withOrigin = null,
  } = {}) {
    let params = { page, per_page: perPage };

    if (byAsset) {
      params.asset__asset_airtable_id = byAsset;
    }
    if (byAlert) {
      params.alert__alert_airtable_id = byAlert;
    }
    if (byDevice) {
      params.device_id = byDevice;
    }
    if (withOrigin) {
      params.origin = withOrigin;
    }

    return this.apiClient.get("api/v1/vibration/", { params: params });
  }
}

export default VibrationService;
